import axios from 'axios';
import Cookies from 'js-cookie';

let request;

export const initializeTopologyApi = () => {
  // basic axios instance
  request = axios.create({
    baseURL: process.env.REACT_APP_TOPOLOGY_API
  });

  // set interceptors for request
  request.interceptors.request.use( (config) => {
    config.headers["Authorization"] = `Bearer ${Cookies.get("token")}`;
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
};

// ----------------- TOPOLOGY -----------------
export const apiGetTopology = () => {
  return request.get('/topology/all',
  ).then( (res) => {
    return res.data;
  });
};

export const apiGetPlantFloor = (data) => {
  return request.get(`/plantFloors/${data}`,
  ).then( (res) => {
    return res.data;
  });
}

export const apiPostPlantFloor = (data) => {
  return request.post('/plantFloors/', data
  ).then( (res) => {
    return res;
  });
}

export const apiPutPlantFloor = (data) => {
  return request.put(`/plantFloors/${data.uuid}`, {
    canvas: data.canvas,
    intervalTime: data.intervalTime
  }).then( (res) => {
    return res;
  });
}