import React from "react";
import { Result, Button } from "antd";
import { FormattedMessage } from "react-intl";

function NoPermission(props) {
  const ShowPage = () => {
    switch (props.permission) {
      case "loading":
        return null;
      case "noReadWritePromission":
        return (
          <Result
            status="error"
            title={<FormattedMessage id="view.noReadWritePromissionTitle" />}
            subTitle={
              <FormattedMessage id="view.noReadWritePromissionSubTitle" />
            }
          />
        );
      case "noMatchCanvasUuid":
        return (
          <Result
            status="error"
            title={<FormattedMessage id="view.noMatchCanvasTitle" />}
            subTitle={<FormattedMessage id="view.noMatchCanvasSubTitle" />}
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  window.open(window.location.origin + "/setting");
                }}
              >
                <FormattedMessage id="view.goToSetting" />
              </Button>,
            ]}
          />
        );
      default:
        return null;
    }
  };

  return ShowPage();
}

export default NoPermission;
