import React, { Component } from "react";
import { FormattedMessage, IntlContext } from "react-intl";
import { fabric } from "fabric";
import {
  apiGetMachineStatuses,
  apiGetFactoryStateCounts,
  apiGetLineStateCounts,
  apiGetPartCounts,
} from "../api/equipment";
import { apiGetAlarmCode } from "../api/oee";
import { apiWorkOrderDetails, apiGetWorkOrderConfig } from "../api/workOrder";
import { statusColors } from "../component/KeyMap";
import { apiPostPlantFloor, apiPutPlantFloor } from "../api/topology";
import { message, Modal, Steps, Tag, Spin } from "antd";
import { workOrderInfoMap } from "./KeyMap";
import * as copy from "copy-to-clipboard";
import queryString from "query-string";
import initAligningGuidelines from "./setting/AligningGuidelines";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Card } from "antd";
import {
  apiGetFileTempLink,
  apiNewFile,
  apiDeleteFile,
} from "../api/filestore";
import { v4 as uuidv4 } from "uuid";
import { AlertOutlined } from "@ant-design/icons";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Taipei");

const { Step } = Steps;
const textPadding = 5;

class Canvas extends Component {
  constructor(props) {
    super(props);
    this.saveLoading = false;
    this.deviceStatusQtyIntervalId = 0;
    this.needSetDeviceStatusQtyInterval = true;
    this.intervalId = 0;
    this.needSetInterval = true;
    this.listener = [];
    this.clickObject = false;
    this.hasBackgroundImage = false;
    this.canvasScaleType = 1;
    this.originalBackgroundImg = null;
    this.currentHover = null;
    this.currentHoverRenderNumber = 0;
    this.scaleRatio = 1;
    this.workOrderConfig = null;
    this.state = {
      cardTitle: "",
      cardContent: null,
      cardExtra: null,
    };
    this.originalBackgroundImgSrc = null;
  }

  async componentDidMount() {
    // Make a New Canvas
    this.canvas = new fabric.Canvas("meCanvas", {
      preserveObjectStacking: true, //選中的物件不會置頂
      width: window.innerWidth, // 讓畫布同視窗大小
      height: window.innerHeight - 34, // 讓畫布同視窗大小 扣掉toolbar的高(上下margin: 7 + icon 20)
    });

    let backgroundColor = this.props.canvasBackgroundColor;
    if (backgroundColor === undefined) {
      backgroundColor = "#C4E5F6";
    }
    this.canvas.setBackgroundColor(backgroundColor);

    // Change the padding logic to include background-color 設備名稱padding
    fabric.Text.prototype.set({
      _getNonTransformedDimensions() {
        // Object dimensions
        return new fabric.Point(this.width, this.height).scalarAdd(textPadding);
      },
      _calculateCurrentDimensions() {
        // Controls dimensions
        return fabric.util.transformPoint(
          this._getTransformedDimensions(),
          this.getViewportTransform(),
          true
        );
      },
    });

    const objectSetting = {
      lockScalingFlip: true, // 不能把物件拉成左右相反
      _controlsVisibility: {
        // 物件只能等比例拖拉放大縮小
        bl: true,
        br: true,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: true,
        tr: true,
        mtr: false,
      },
    };
    fabric.Text.prototype.set(objectSetting);
    fabric.Group.prototype.set(objectSetting);
    fabric.Image.prototype.set(objectSetting);

    // Load Temp from Json
    const str = window.location.pathname;
    let path = str.split("/");
    if (
      this.props.plantFloorCanvas !== null &&
      this.props.plantFloorCanvas !== ""
    ) {
      // 調整畫布load進來的的大小(倍數)
      let scaleRatio = this.scaleCanvas(
        JSON.parse(this.props.plantFloorCanvas)
      );
      this.scaleRatio = scaleRatio;
      if (path.length > 2 && path[2] !== undefined && path[2] !== "") {
        let plantFloorCanvasObject = JSON.parse(this.props.plantFloorCanvas);
        if (plantFloorCanvasObject.hasOwnProperty("objects")) {
          for (const obj of plantFloorCanvasObject["objects"]) {
            if (obj.name === "backgroundImg") {
              // 去 filestore 拿背景圖
              if (obj.src.substr(0, 10) !== "data:image") {
                this.originalBackgroundImgSrc = obj.src;
                await apiGetFileTempLink(obj.src).then((res) => {
                  obj.src = res.url;
                });
              }
            }
          }
        }
        switch (path[1]) {
          case "view":
            // /view/uuid
            apiGetWorkOrderConfig().then((res) => {
              this.workOrderConfig = res;
            });
            let objectArray = [];
            let deviceStatusArray = [];
            this.canvas.loadFromJSON(
              plantFloorCanvasObject,
              () => {
                this.canvas.renderAll();
                this.changeDeviceBackgroundColor(objectArray);
                this.changeDeviceStatusQty(deviceStatusArray);
                this.canvas.setZoom(this.canvas.zoom * scaleRatio);
                this.canvas.setWidth(this.canvas.width * scaleRatio);
                this.canvas.setHeight(this.canvas.height * scaleRatio);
              },
              (o, object) => {
                if (
                  object.selectedType === "device" ||
                  !object.hasOwnProperty("selectedType")
                ) {
                  // device 或 沒有selectedType
                  objectArray.push(object);
                } else if (object.selectedType === "deviceStatus") {
                  deviceStatusArray.push(object);
                }
                object.selectable = false;
              }
            );
            break;
          case "setting":
            this.canvas.loadFromJSON(
              plantFloorCanvasObject,
              () => {
                this.canvas.renderAll();
                this.canvasScaleType = this.canvas.canvasScaleType;
                this.canvas.setZoom(this.canvas.zoom * scaleRatio);
                this.canvas.setWidth(this.canvas.width * scaleRatio);
                this.canvas.setHeight(this.canvas.height * scaleRatio);
              },
              (o, object) => {
                if (object.name === "backgroundImg") {
                  object.set({
                    selectable: false,
                    editable: false,
                  });
                  this.hasBackgroundImage = true;
                  this.props.setUploadPicture(object.src);
                }
                if (object.type === "i-text") {
                  object.editable = false; // 不可修改設備名字
                }
              }
            );
            this.props.setUpdateFlag();
            break;
          default:
            break;
        }
      }
    } else {
      if (path.length > 1) {
        switch (path[1]) {
          case "setting":
            this.addWhiteCubeToCanvas(null, "setting");
            this.props.setCanvasInitOk(true);
            break;
          case "view":
            // if path[1] === view, and the canvas is empty string, means this is canvas not setting yet
            // get parent window width and height from parameter
            const parameters = queryString.parse(window.location.search);
            if (
              parameters.windowWidth !== null &&
              parameters.windowWidth !== undefined &&
              parameters.windowHeight !== null &&
              parameters.windowHeight !== undefined
            ) {
              const parentWindowWidth =
                isNaN(parseInt(parameters.windowWidth)) === false
                  ? parseInt(parameters.windowWidth)
                  : window.innerWidth;
              const parentWindowHeight =
                isNaN(parseInt(parameters.windowHeight)) === false
                  ? parseInt(parameters.windowHeight)
                  : window.innerHeight;

              let scaleRatio = this.scaleCanvas({
                width: parentWindowWidth,
                height: parentWindowHeight,
              });
              this.canvas.setZoom(scaleRatio);

              this.canvas.setWidth(parentWindowWidth * scaleRatio);
              this.canvas.setHeight((parentWindowHeight - 34) * scaleRatio);
              this.addWhiteCubeToCanvas(parentWindowWidth, "view");

              for (let i = 0; i < this.canvas._objects.length; i++) {
                this.canvas._objects[i].selectable = false;
              }

              this.canvas.renderAll();
            }
            break;
          default:
            break;
        }
      }
    }

    this.canvas.on({
      "object:moving": (e) => {
        // 設定邊界
        const obj = e.target;
        obj.setCoords();
        const { top, left, width, height } = obj.getBoundingRect();
        if (top < 0) {
          obj.top = 0;
        }

        if (top + height > this.canvas.getHeight()) {
          obj.top = this.canvas.getHeight() - height;
        }
        if (left < 0) {
          obj.left = 0;
        }
        if (left + width > this.canvas.getWidth()) {
          obj.left = this.canvas.getWidth() - width;
        }
        this.canvas.renderAll();

        this.clickObject = false; // 拖拉 不出現抽屜
      },
      "mouse:down": () => {
        // 點一下
        if (
          this.canvas.getActiveObject() !== undefined &&
          this.canvas.getActiveObject() !== null &&
          ((this.canvas.getActiveObject().hasOwnProperty("name") &&
            this.canvas.getActiveObject().name !== "backgroundImg") ||
            !this.canvas.getActiveObject().hasOwnProperty("name"))
        ) {
          this.clickObject = true;
        }
      },
      "mouse:up": () => {
        // 點一下後 出現抽屜
        if (this.clickObject) {
          if (this.canvas.getActiveObjects().length > 0) {
            // console.log('點', this.canvas.getActiveObjects())
            this.props.setShowToolBar(true);
          } else {
            // 不出現抽屜
            this.props.setShowToolBar(false);
          }
        }
      },
      "selection:cleared": () => {
        this.props.setShowToolBar(false);
      },
    });

    // 按Backspace及Delete刪除設備及圖標
    window.addEventListener("keyup", (e) => {
      if (e.key === "Backspace" || e.key === "Delete") {
        // 檢查是否是backspace、Delete被按的話，呼叫刪除的那個功能
        this.deleteItem();
      }
    });

    window.addEventListener("resize", (e) => {
      if (this.listener.length === 0) {
        this.listener.push(
          setTimeout(() => {
            // set canvas zoom
            const str = window.location.pathname;
            let path = str.split("/");
            if (
              path[1] === "setting" &&
              (path[2] === "" || path[2] === undefined)
            ) {
              // setting
              this.canvasScaleForDifferentRatio(this.props.canvasScaleType);
              if (
                this.canvas.backgroundImage !== undefined &&
                this.canvas.backgroundImage !== null
              ) {
                // 調整背景圖寬高
                this.scaleImg(this.canvas.backgroundImage);
              }
            } else {
              // setting/uuid 、 view/uuid
              let scaleRatio = this.scaleCanvas({
                width: this.canvas.width,
                height: this.canvas.height,
              });
              this.canvas.setZoom(scaleRatio * this.canvas.getZoom());
              this.canvas.setWidth(this.canvas.width * scaleRatio);
              this.canvas.setHeight(this.canvas.height * scaleRatio);
            }
            // clear timeout & set to init
            clearTimeout(this.listener[0]);
            this.listener = [];
          }, 1000)
        );
      } else {
        if (this.listener.length > 1) {
          clearTimeout(this.listener[0]);
          this.listener.shift();
        }
      }
    });
    initAligningGuidelines(this.canvas);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.canvas.setBackgroundColor(nextProps.canvasBackgroundColor);
    this.canvas.renderAll();
    if (nextProps.uploadPicture !== this.props.uploadPicture) {
      if (nextProps.uploadPicture === null) {
        let findImage = this.canvas._objects.find(
          (item) =>
            item.type === "image" && item.getSrc() === this.props.uploadPicture
        );
        if (findImage !== undefined) {
          this.canvas.remove(findImage);
          this.canvas.renderAll();
        }
        this.hasBackgroundImage = false;
        return;
      }
      if (this.hasBackgroundImage === false) {
        // 沒有背景圖 => 新增背景圖
        new fabric.Image.fromURL(nextProps.uploadPicture, this.scaleImg);
        this.hasBackgroundImage = true;
      } else {
        // 有背景圖 => 替換背景圖
        let findImage = this.canvas._objects.find(
          (item) =>
            item.type === "image" && item.getSrc() === this.props.uploadPicture
        );
        if (findImage !== undefined) {
          findImage.setSrc(nextProps.uploadPicture, (img) => {
            let scaleFactor = this.getImgScaleFactor(img);
            img.set({
              scaleX: scaleFactor,
              scaleY: scaleFactor,
            });
            this.canvas.renderAll();
          });
        }
      }
    }
    if (nextProps.canvasScaleType !== this.props.canvasScaleType) {
      this.canvasScaleForDifferentRatio(nextProps.canvasScaleType);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    window.removeEventListener("keyup", (e) => {
      if (e.key === "Backspace" || e.key === "Delete") {
        this.deleteItem();
      }
    });

    window.removeEventListener("resize", (e) => {
      if (this.listener.length === 0) {
        this.listener.push(
          setTimeout(() => {
            // set canvas zoom
            const str = window.location.pathname;
            let path = str.split("/");
            if (
              path[1] === "setting" &&
              (path[2] === "" || path[2] === undefined)
            ) {
              // setting
              this.canvasScaleForDifferentRatio(this.props.canvasScaleType);
              if (
                this.canvas.backgroundImage !== undefined &&
                this.canvas.backgroundImage !== null
              ) {
                // 調整背景圖寬高
                this.scaleImg(this.canvas.backgroundImage);
              }
            } else {
              // setting/uuid 、 view/uuid
              let scaleRatio = this.scaleCanvas({
                width: this.canvas.width,
                height: this.canvas.height,
              });
              this.canvas.setZoom(scaleRatio * this.canvas.getZoom());
              this.canvas.setWidth(this.canvas.width * scaleRatio);
              this.canvas.setHeight(this.canvas.height * scaleRatio);
            }
            // clear timeout & set to init
            clearTimeout(this.listener[0]);
            this.listener = [];
          }, 1000)
        );
      } else {
        if (this.listener.length > 1) {
          clearTimeout(this.listener[0]);
          this.listener.shift();
        }
      }
    });
  }

  addWhiteCubeToCanvas = (tmpWindowWidth, type) => {
    let windowWidth = window.innerWidth;

    if (tmpWindowWidth !== null && tmpWindowWidth !== undefined) {
      windowWidth = tmpWindowWidth;
    }

    // 白底
    let whiteCube = "/machineIcons/whiteCube.png";
    let selectable = type === "view" ? false : true;
    new fabric.Image.fromURL(whiteCube, (img) => {
      img.set({
        left: 762.78 * (windowWidth / 1920),
        top: 16.79 * (windowWidth / 1920),
        // width:1101,
        // height:710,
        originX: "left",
        originY: "top",
        scaleX: 0.8 * (windowWidth / 1920),
        scaleY: 0.8 * (windowWidth / 1920),
        selectable: selectable,
      });
      this.canvas.add(img);
    });
    new fabric.Image.fromURL(whiteCube, (img) => {
      img.set({
        left: 220 * (windowWidth / 1920),
        top: 360 * (windowWidth / 1920),
        // width:1101,
        // height:710,
        originX: "left",
        originY: "top",
        scaleX: 0.8 * (windowWidth / 1920),
        scaleY: 0.8 * (windowWidth / 1920),
        selectable: selectable,
      });
      this.canvas.add(img);
    });
  };

  canvasScaleForDifferentRatio = async (newCanvasScaleType) => {
    // dashboard
    let originHeight = this.canvas.height; // 原畫布高
    let newHeight = 0; // 新畫布高
    if (newCanvasScaleType === 1) {
      newHeight = window.innerHeight - 34;
    } else {
      newHeight = window.innerWidth / newCanvasScaleType;
    }
    this.canvas.setWidth(window.innerWidth);
    this.canvas.setHeight(newHeight);
    let newScale = originHeight / newHeight;
    let objArray = [];
    for (const obj of this.canvas._objects) {
      await new Promise((resolve, reject) => {
        obj.clone(
          (item) => {
            item.set({
              scaleX: obj.scaleX / newScale,
              scaleY: obj.scaleY / newScale,
              top: obj.top / newScale,
              left: obj.left / newScale,
            });
            objArray.push(item);
            resolve();
          },
          ["name", "device", "deviceUUID"]
        );
      });
    }
    this.canvas.remove(...this.canvas.getObjects());
    this.canvas.add(...objArray);
    this.canvas.renderAll();
    this.canvasScaleType = newCanvasScaleType;
  };

  // 調整畫布load進來的的大小(倍數)
  scaleCanvas = (canvasObj) => {
    let containerWidth = window.innerWidth;
    let containerHeight = window.innerHeight - 34;
    return Math.min(
      containerWidth / canvasObj.width,
      containerHeight / canvasObj.height
    );
  };

  // 依照畫面長寬調整背景圖片大小
  scaleImg = (img) => {
    let scaleFactor = this.getImgScaleFactor(img);
    img.set(
      {
        top: 0,
        left: 0,
        originX: "left",
        originY: "top",
        scaleX: scaleFactor,
        scaleY: scaleFactor,
        selectable: false,
        name: "backgroundImg",
      },
      ["name"]
    );
    this.canvas.add(img);
    this.canvas.sendToBack(img);
    this.canvas.renderAll();
    this.hasBackgroundImage = true;
  };

  getImgScaleFactor = (img) => {
    let canvasWidth = this.canvas.getWidth();
    let canvasHeight = this.canvas.getHeight();
    let imgWidth = img.width;
    let imgHeight = img.height;

    let canvasAspect = canvasWidth / canvasHeight;
    let imgAspect = imgWidth / imgHeight;
    let scaleFactor; //left, top,

    if (
      (canvasAspect >= 1 && imgAspect >= 1) ||
      (canvasAspect >= 1 && imgAspect < 1)
    ) {
      // 1. 表示畫布是水平，圖片也是水平，那我們就讓圖的高度符合，寬度跟著調整
      // 2. 表示畫布是水平，圖片卻是垂直
      scaleFactor =
        canvasHeight > imgHeight
          ? imgHeight / canvasHeight
          : canvasHeight / imgHeight;
    }

    if (
      (canvasAspect < 1 && imgAspect < 1) ||
      (canvasAspect < 1 && imgAspect >= 1)
    ) {
      // 1. 表示畫布是垂直，圖片也是垂直，那我們就讓圖的寬度符合，高度跟著調整
      // 2. 表示畫布是垂直，圖片是水平，那我們就讓圖的寬度符合，高度跟著調整
      scaleFactor =
        canvasWidth > imgWidth
          ? imgWidth / canvasWidth
          : canvasWidth / imgWidth;
    }

    // top = (canvasHeight - (imgHeight * scaleFactor)) / 2;
    // left = (canvasWidth - (imgWidth * scaleFactor)) / 2;

    return scaleFactor;
  };

  // 編輯背景圖
  editBackgroundImg = () => {
    // 背景圖
    let img = this.canvas._objects.find(
      (item) => item.name === "backgroundImg"
    );
    this.originalBackgroundImg = JSON.parse(JSON.stringify(img));

    img.set({
      selectable: true,
    });
    this.canvas.setActiveObject(img);
    this.canvas.bringToFront(img); // 將背景圖推到最上層
    this.canvas.renderAll();

    // 畫布上的物件
    for (const item of this.canvas.getObjects()) {
      if (item.name !== "backgroundImg") {
        item.set({
          opacity: 0, // 隱藏物件
          selectable: false,
        });
        this.canvas.renderAll();
      }
    }
  };

  // 取消編輯背景圖
  cancelEditBackgroundImg = () => {
    let img = this.canvas._objects.find(
      (item) => item.name === "backgroundImg"
    );
    img.set({
      selectable: false,
      editable: false,
    });
    Object.assign(img, this.originalBackgroundImg);
    this.canvas.discardActiveObject(); // 取消選取物件
    this.canvas.sendToBack(img); // 將背景圖推到最底層
    this.canvas.renderAll();

    // 畫布上的物件
    for (const item of this.canvas.getObjects()) {
      if (item.name !== "backgroundImg") {
        item.set({
          opacity: 1, // 取消隱藏物件
          selectable: true,
        });
        this.canvas.renderAll();
      }
    }
  };

  // 完成編輯背景圖
  saveBackgroundImg = () => {
    // 背景圖
    let img = this.canvas._objects.find(
      (item) => item.name === "backgroundImg"
    );
    img.set({
      selectable: false,
      editable: false,
    });
    this.canvas.discardActiveObject(); // 取消選取物件
    this.canvas.sendToBack(img); // 將背景圖推到最底層
    this.canvas.renderAll();

    // 畫布上的物件
    for (const item of this.canvas.getObjects()) {
      if (item.name !== "backgroundImg") {
        item.set({
          opacity: 1, // 取消隱藏物件
          selectable: true,
        });
        this.canvas.renderAll();
      }
    }
  };

  // change Text background color when loading
  changeDeviceBackgroundColor = (objectArray) => {
    let deviceTextObject = {};
    for (const obj of objectArray) {
      if (obj.type === "group") {
        let deviceTextObj = obj._objects.find(
          (item) => item.type === "i-text" || item.type === "text"
        );
        if (
          deviceTextObj !== undefined &&
          deviceTextObj.hasOwnProperty("device")
        ) {
          deviceTextObject[deviceTextObj.device.uuid] = deviceTextObj;
          obj.on("mousedown", (e) => {
            window.open(
              `${process.env.REACT_APP_DASHBOARD_SINGLE_MACHINE_URL}?targetName=${e.target.device.name}&targetScope=device`
            );
          });
          // 有圖標的設備
          obj.on("mouseover", (e) => {
            this.currentHover = deviceTextObj.device.uuid;
            this.setState({ cardTitle: deviceTextObj.text });
            let cardHeight = 110;
            let cardWidth = 350;
            let info = (
              <div>
                {" "}
                <Spin />{" "}
              </div>
            );

            // 拿設備 alarmCode
            this.getAlarmCode(deviceTextObj.device.uuid);

            apiWorkOrderDetails(deviceTextObj.device.uuid).then(async (res) => {
              if (res.start.data.length > 0) {
                this.workOrderInfo(res);
              } else {
                cardHeight -= 5;
                info = (
                  <div style={{ fontWeight: "bold" }}>
                    <FormattedMessage id="tooltip.noWorkOrder" />
                  </div>
                );
              }

              if (
                this.currentHover === deviceTextObj.device.uuid &&
                this.currentHoverRenderNumber === 0
              ) {
                let node = document.getElementById("tooltip");
                let top = 0;
                let left = 0;
                if (obj.angle / 90 === 0) {
                  // 正
                  top =
                    (obj.top +
                      (obj.height / 2 + deviceTextObj.top) * obj.scaleY) *
                    this.scaleRatio;
                  left =
                    (obj.left +
                      (obj.width / 2 +
                        deviceTextObj.left +
                        deviceTextObj.width +
                        textPadding) *
                        obj.scaleX) *
                      this.scaleRatio +
                    5;
                } else if (obj.angle / 90 === -1 || obj.angle / 90 === 3) {
                  // 左
                  top =
                    (obj.top +
                      (obj.width / 2 -
                        deviceTextObj.left -
                        deviceTextObj.width -
                        textPadding) *
                        obj.scaleX) *
                    this.scaleRatio;
                  left =
                    (obj.left +
                      (obj.height / 2 +
                        deviceTextObj.top +
                        deviceTextObj.height +
                        textPadding) *
                        obj.scaleY) *
                      this.scaleRatio +
                    5;
                } else if (obj.angle / 90 === -2 || obj.angle / 90 === 2) {
                  // 反
                  top =
                    (obj.top +
                      (-1 * deviceTextObj.top +
                        -1 * deviceTextObj.height +
                        -1 * textPadding +
                        obj.height / 2) *
                        obj.scaleY) *
                    this.scaleRatio;
                  left =
                    (obj.left +
                      (-1 * deviceTextObj.left + obj.width / 2) * obj.scaleX) *
                      this.scaleRatio +
                    5;
                } else if (obj.angle / 90 === 1 || obj.angle / 90 === -3) {
                  // 右
                  top =
                    (obj.top +
                      (obj.width / 2 + deviceTextObj.left) * obj.scaleY) *
                    this.scaleRatio;
                  left =
                    (obj.left +
                      (obj.height / 2 - deviceTextObj.top) * obj.scaleX) *
                      this.scaleRatio +
                    5;
                }

                // 超出畫布
                if (top + cardHeight > this.canvas.height) {
                  top = this.canvas.height - cardHeight;
                }

                if (left + cardWidth > this.canvas.width) {
                  if (
                    obj.angle / 90 === 0 ||
                    obj.angle / 90 === 2 ||
                    obj.angle / 90 === -2
                  ) {
                    left =
                      left -
                      (deviceTextObj.width + textPadding) * obj.scaleX -
                      (cardWidth + 10);
                  } else {
                    left =
                      left -
                      deviceTextObj.height * obj.scaleY * this.scaleRatio -
                      (cardWidth + 10);
                  }
                }
                node.style.display = "block";
                node.style.position = "absolute";
                node.style.top = `${top + 34}px`;
                node.style.left = `${left}px`;
                this.setState({ cardContent: info });
                this.currentHoverRenderNumber = 1;
              }
            });
          });

          obj.on("mouseout", (e) => {
            this.currentHover = null;
            this.currentHoverRenderNumber = 0;
            let node = document.getElementById("tooltip");
            node.style.display = "none";
          });
        }
      } else if (obj.type === "text" || obj.type === "i-text") {
        deviceTextObject[obj.device.uuid] = obj;
        obj.on("mousedown", (e) => {
          window.open(
            `${process.env.REACT_APP_DASHBOARD_SINGLE_MACHINE_URL}?targetName=${e.target.device.name}&targetScope=device`
          );
        });

        obj.on("mouseover", (e) => {
          this.currentHover = obj.device.uuid;
          this.setState({ cardTitle: obj.text });
          let cardHeight = 110;
          let cardWidth = 350;
          let info = (
            <div>
              {" "}
              <Spin />{" "}
            </div>
          );

          // 拿設備 alarmCode
          this.getAlarmCode(obj.device.uuid);

          apiWorkOrderDetails(obj.device.uuid).then(async (res) => {
            if (res.start.data.length > 0) {
              this.workOrderInfo(res);
            } else {
              cardHeight -= 5;
              info = (
                <div style={{ fontWeight: "bold" }}>
                  <FormattedMessage id="tooltip.noWorkOrder" />
                </div>
              );
            }

            if (
              this.currentHover === obj.device.uuid &&
              this.currentHoverRenderNumber === 0
            ) {
              let node = document.getElementById("tooltip");
              let top = obj.top * this.scaleRatio;
              // 橫的
              let left =
                (obj.angle / 90 === 0 ||
                obj.angle / 90 === 2 ||
                obj.angle / 90 === -2
                  ? obj.left + (obj.width + textPadding) * obj.scaleX
                  : obj.left + (obj.height + textPadding) * obj.scaleY) *
                  this.scaleRatio +
                5;
              // 超出畫布
              if (top + cardHeight > this.canvas.height) {
                top = this.canvas.height - cardHeight;
              }
              if (left + cardWidth + 5 > this.canvas.width) {
                left = obj.left * this.scaleRatio - (cardWidth + 5);
              }
              node.style.display = "block";
              node.style.position = "absolute";
              node.style.top = `${top + 34}px`;
              node.style.left = `${left}px`;
              this.setState({ cardContent: info });
              this.currentHoverRenderNumber = 1;
            }
          });
        });
        obj.on("mouseout", (e) => {
          this.currentHover = null;
          this.currentHoverRenderNumber = 0;
          let node = document.getElementById("tooltip");
          node.style.display = "none";
        });
      }
    }
    this.setMachineStatusInterval(deviceTextObject);
  };

  workOrderInfo = async (res) => {
    let node = document.getElementById("tooltip");
    let obj = [];
    let count = 0;
    let data = {
      start: null,
      end: dayjs().toISOString(),
      uuid: null,
    };
    for (const item of workOrderInfoMap) {
      let value = res.start.data[0][item.key];
      switch (item.key) {
        case "start_time":
          data.start = dayjs(value).toISOString();
          value = dayjs(value).format("YYYY-MM-DD HH:mm:ss");
          break;
        case "qty":
          value = `${value} ${
            this.workOrderConfig.work_order_unit_default_value === null
              ? ""
              : this.workOrderConfig.work_order_unit_default_value
          }`;
          break;
        case "abnormal_status":
          value = `${value === null ? 0 : Object.keys(value).length} 次`;
          break;
        case "workOrderProgress":
          data.uuid = res.start.data[0].device_uuid;
          let partCounts = await apiGetPartCounts(data);
          value = parseFloat(
            (partCounts.sum /
              this.workOrderConfig.work_order_unit_ratio /
              res.start.data[0].qty) *
              100
          ).toFixed(1);
          value =
            (value * 10) % 10 === 0 ? `${parseInt(value)} %` : `${value} %`;
          break;
        default:
          break;
      }
      obj.push(
        <div key={item.key} style={{ display: "flex" }}>
          <Card
            size="small"
            bordered={false}
            className="info-key"
            style={{ background: count % 2 ? "white" : "#F4F6F7" }}
          >
            <FormattedMessage id={`tooltip.${item.key}`} />
          </Card>

          <Card
            size="small"
            bordered={false}
            className="info-value"
            style={{ background: count % 2 ? "white" : "#F4F6F7" }}
          >
            {value}
          </Card>
        </div>
      );
      count += 1;
    }
    if (
      this.currentHover === res.start.data[0].device_uuid &&
      this.currentHoverRenderNumber === 1
    ) {
      // 超出畫布
      let cardHeight = 401;
      let { top } = node.getBoundingClientRect();
      if (top + cardHeight > this.canvas.height) {
        top = (this.canvas.height - cardHeight) * this.scaleRatio;
        node.style.top = `${top + 34}px`;
      }

      this.setState({ cardContent: obj });
    }
  };

  changeDeviceStatusQty = (deviceStatusArray) => {
    Promise.allSettled(
      deviceStatusArray.map((item) => {
        if (item.selectedProperty.type === "factory") {
          return apiGetFactoryStateCounts(
            item.selectedProperty.uuid[item.selectedProperty.uuid.length - 1]
          );
        } else if (item.selectedProperty.type === "line") {
          return apiGetLineStateCounts(
            item.selectedProperty.uuid[item.selectedProperty.uuid.length - 1]
          );
        } else {
          return null;
        }
      })
    ).then((res) => {
      for (const [index, result] of res.entries()) {
        // let uuid = deviceStatusArray[index];
        if (result.status === "fulfilled") {
          for (const group of deviceStatusArray[index]._objects) {
            if (group.type === "text") {
              continue;
            } else {
              let findText = group._objects.find((obj) => {
                return obj.type === "text";
              });
              if (findText !== undefined) {
                if (result.value !== null) {
                  findText.set("text", `${result.value[group.status]}`);
                } else {
                  // 撈不到資料
                  findText.set("text", `-`);
                }
              }
            }
          }
        }
      }
      this.canvas.renderAll();
      if (this.needSetDeviceStatusQtyInterval) {
        this.needSetDeviceStatusQtyInterval = false;
        this.deviceStatusQtyIntervalId = setInterval(() => {
          this.changeDeviceStatusQty(deviceStatusArray);
        }, this.props.intervalTime * 1000);
      }
    });
  };

  // set interval
  setMachineStatusInterval = (deviceTextObject) => {
    let uuids = null;
    for (const uuid of Object.keys(deviceTextObject)) {
      if (uuids === null) {
        uuids = uuid;
      } else {
        uuids = uuids + "," + uuid;
      }
    }
    if (uuids !== null) {
      apiGetMachineStatuses(uuids).then((res) => {
        if (res !== null) {
          let result = res.reduce(function (map, obj) {
            map[obj.device_uuid] = obj.state;
            return map;
          }, {});

          for (const uuid of Object.keys(deviceTextObject)) {
            if (result.hasOwnProperty(uuid)) {
              deviceTextObject[uuid].set({
                backgroundColor: statusColors[result[uuid]],
              });
            } else {
              deviceTextObject[uuid].set({ backgroundColor: "white" });
            }
          }
          this.canvas.renderAll();
          if (this.needSetInterval) {
            this.needSetInterval = false;
            this.intervalId = setInterval(() => {
              this.setMachineStatusInterval(deviceTextObject);
            }, this.props.intervalTime * 1000);
          }
        }
      });
    }
  };

  copyItem = () => {
    if (this.canvas.getActiveObjects().length === 0) {
      // 沒有點選物件 不可複製
      return;
    } else if (this.canvas.getActiveObjects().length === 1) {
      // 選了一個物件 group text image
      this.setCopyItemPosition({ copyData: this.canvas.getActiveObjects() });
    } else {
      // 選了兩個以上的物件 group text image
      let groupWidth = this.canvas.getActiveObject().width;
      let groupHeight = this.canvas.getActiveObject().height;
      let groupLeft = this.canvas.getActiveObject().left;
      let groupTop = this.canvas.getActiveObject().top;
      this.setCopyItemPosition({
        copyData: this.canvas.getActiveObjects(),
        groupWidth,
        groupHeight,
        groupLeft,
        groupTop,
      });
    }
  };

  setCopyItemPosition = (item) => {
    for (const obj of item.copyData) {
      let left =
        item.groupLeft !== undefined
          ? item.groupLeft + (item.groupWidth / 2 + obj.left)
          : obj.left;
      let top =
        item.groupTop !== undefined
          ? item.groupTop + (item.groupHeight / 2 + obj.top)
          : obj.top;
      let width =
        obj.angle / 90 === 0 || obj.angle / 90 === 2 || obj.angle / 90 === -2
          ? obj.width
          : obj.height;
      if (left + width * 2 + 10 > this.canvas.getWidth()) {
        left = left + 10;
        top = top + 10;
      } else {
        left = left + width + 10;
      }
      if (
        obj.selectedType === "device" ||
        !obj.hasOwnProperty("selectedType")
      ) {
        if (obj.type === "group") {
          const objectsInObj = [];

          obj._objects.forEach((itemsInObj) => {
            const itemsInObjClone = fabric.util.object.clone(itemsInObj);
            if (itemsInObjClone.type === "text") {
              itemsInObjClone.set({
                text: this.context.formatMessage({
                  id: "setting.canvas.noDevice",
                }),
              });
              delete itemsInObjClone.device;
              delete itemsInObjClone.deviceUUID;
            }
            objectsInObj.push(itemsInObjClone);
          });
          const newObj = new fabric.Group(objectsInObj, {
            left: left,
            top: top,
            originY: obj.originY,
            originX: obj.originX,
            angle: obj.angle,
          });

          this.canvas.add(newObj);
          this.canvas.renderAll();
        } else if (obj.type === "text") {
          obj.clone((item) => {
            item.set({
              text: this.context.formatMessage({
                id: "setting.canvas.noDevice",
              }),
              left: left,
              top: top,
              originY: obj.originY,
              originX: obj.originX,
              angle: obj.angle,
            });
            this.canvas.add(item);
          });
        } else if (obj.type === "image") {
          obj.clone(
            (item) => {
              item.set({
                left: left,
                top: top,
                originY: obj.originY,
                originX: obj.originX,
                angle: obj.angle,
              });
              this.canvas.add(item);
            },
            ["name"]
          );
        }
      } else if (obj.selectedType === "word") {
        obj.clone(
          (item) => {
            item.set({
              left: left,
              top: top,
              originY: obj.originY,
              originX: obj.originX,
              angle: obj.angle,
            });
            this.canvas.add(item);
          },
          ["selectedType"]
        );
      } else if (obj.selectedType === "deviceStatus") {
        obj.clone(
          (item) => {
            item.set({
              left: left,
              top: top,
              originY: obj.originY,
              originX: obj.originX,
              angle: obj.angle,
            });
            this.canvas.add(item);
          },
          ["selectedType", "selectedProperty", "status"]
        );
      }
    }
  };

  // 至最上層
  bringToFront = () => {
    this.canvas.bringToFront(this.canvas.getActiveObject());
    this.canvas.renderAll();
  };

  // 往上一層
  bringForward = () => {
    this.canvas.bringForward(this.canvas.getActiveObject());
  };

  // 往下一層
  sendBackwards = () => {
    this.canvas.sendBackwards(this.canvas.getActiveObject());

    // 讓背景圖片永遠保持在最下層
    let findBackgroundImg = this.canvas._objects.find(
      (item) => item.name === "backgroundImg"
    );
    if (findBackgroundImg !== undefined) {
      this.canvas.sendToBack(findBackgroundImg);
    }
  };

  // 至最下層
  sendToBack = () => {
    this.canvas.sendToBack(this.canvas.getActiveObject());

    // 讓背景圖片永遠保持在最下層
    let findBackgroundImg = this.canvas._objects.find(
      (item) => item.name === "backgroundImg"
    );
    if (findBackgroundImg !== undefined) {
      this.canvas.sendToBack(findBackgroundImg);
    }
  };

  // 向左旋轉90度
  leftRotate = () => {
    let rotateItem = this.canvas.getActiveObject();
    if (rotateItem !== null) {
      const { top, left } = rotateItem.getBoundingRect();
      let changeXandY = {
        "left,top": ["right", "top"],
        "right,top": ["right", "bottom"],
        "right,bottom": ["left", "bottom"],
        "left,bottom": ["left", "top"],
      };

      const angle = rotateItem.angle - 90 === -360 ? 0 : rotateItem.angle - 90;
      rotateItem.rotate(angle); // 設定旋轉角度
      this.canvas.renderAll();

      rotateItem.set({
        // 旋轉後左上角的點作為originX、originY，將原先的left、top座標設定給新的左上角的點
        originX: changeXandY[rotateItem.originX + "," + rotateItem.originY][0],
        originY: changeXandY[rotateItem.originX + "," + rotateItem.originY][1],
        left: left,
        top: top,
      });
      this.canvas.renderAll();
    }
  };

  // 向右旋轉90度
  rightRotate = () => {
    let rotateItem = this.canvas.getActiveObject();
    if (rotateItem !== null) {
      const { top, left } = rotateItem.getBoundingRect();
      let changeXandY = {
        "left,top": ["left", "bottom"],
        "left,bottom": ["right", "bottom"],
        "right,bottom": ["right", "top"],
        "right,top": ["left", "top"],
      };
      const angle = rotateItem.angle + 90 === 360 ? 0 : rotateItem.angle + 90;
      rotateItem.rotate(angle); // 設定旋轉角度
      this.canvas.renderAll();

      rotateItem.set({
        // 旋轉後左上角的點作為originX、originY，將原先的left、top座標設定給新的左上角的點
        originX: changeXandY[rotateItem.originX + "," + rotateItem.originY][0],
        originY: changeXandY[rotateItem.originX + "," + rotateItem.originY][1],
        left: left,
        top: top,
      });
      this.canvas.renderAll();
    }
  };

  // delete
  deleteItem = () => {
    let removeItem = this.canvas.getActiveObject();
    if (removeItem !== undefined && removeItem !== null) {
      if (removeItem.name === "backgroundImg") {
        // 不能使用此功能刪除背景圖
        return;
      }
      this.canvas.remove(removeItem);
      if (removeItem.hasOwnProperty("deviceUUID")) {
        this.props.setNewDeviceOption(removeItem.deviceUUID, false); // deviceUUID,  disabled
      }
    }
  };

  // edit 編輯物件
  editItem = () => {
    if (!this.canvas.getActiveObject()) {
      // 沒有點選任何物件 無法編輯
      return;
    }
    if (this.canvas.getActiveObjects().length > 1) {
      // 點選超過一個物件 無法編輯
      return;
    }
    this.canvas.getActiveObject().clone(
      (item) => {
        this.canvas.add(item);
        this.canvas.remove(this.canvas.getActiveObject());
        this.props.setGetEditItem(item);
        this.props.setAddAndEditModalVisible(true);
      },
      [
        "device",
        "deviceUUID",
        "name",
        "selectedType",
        "selectedProperty",
        "status",
      ]
    );
  };

  // save
  saveCanvas = async () => {
    if (this.saveLoading) {
      return;
    }

    this.saveLoading = true;
    const str = window.location.pathname;
    let path = str.split("/");
    if (path[2] !== "" && path[2] !== undefined) {
      // PUT
      // console.log('1--this.canvas', this.canvas)
      let canvasJson = this.canvas.toJSON([
        "width",
        "height",
        "device",
        "deviceUUID",
        "name",
        "selectedType",
        "selectedProperty",
        "status",
        "zoom",
      ]);
      let checkBackgroundImgResult = await this.checkBackgroundImg(canvasJson);
      // console.log('2--canvasJson', canvasJson)
      canvasJson.width = this.canvas.width;
      canvasJson.height = this.canvas.height;
      canvasJson.zoom = this.canvas.getZoom();
      canvasJson.canvasScaleType = this.canvasScaleType;
      const saveJSON = JSON.stringify(canvasJson);
      if (checkBackgroundImgResult) {
        apiPutPlantFloor({
          uuid: path[2],
          canvas: saveJSON,
          intervalTime:
            this.props.intervalTime === null ? 30 : this.props.intervalTime,
        })
          .then((res) => {
            setTimeout(msg, 0);
            if (res.status.toString() === "200") {
              this.saveSuccess(path[2]); // show success modal
            } else {
              message.error(
                this.context.formatMessage({ id: "setting.canvas.saveFailed" })
              );
            }
          })
          .catch((err) => {
            if (err.message === "Network Error") {
              // 圖檔太大無法儲存
              setTimeout(msg, 0);
              message.error(
                this.context.formatMessage({
                  id: "setting.canvas.saveFailed",
                }) +
                  this.context.formatMessage({
                    id: "setting.canvas.overFileSizeLimit",
                  })
              );
              return;
            }

            if (err.response.status.toString() === "401") {
              // token過期
              setTimeout(msg, 0);
              message.error(
                this.context.formatMessage({
                  id: "setting.canvas.networkAbnormal",
                })
              );
            }

            if (err.response.status.toString() === "500") {
              // 儲存失敗
              setTimeout(msg, 0);
              message.error(
                this.context.formatMessage({
                  id: "setting.canvas.saveFailed",
                })
              );
            }
          })
          .then(() => {
            this.saveLoading = false;
          });
        const msg = message.loading(
          this.context.formatMessage({
            id: "setting.canvas.saving",
          }),
          0
        );
      } else {
        // 背景圖儲存失敗
        message.error(
          this.context.formatMessage({
            id: "setting.canvas.backgroundImgSaveFailed",
          })
        );
        this.saveLoading = false;
      }
    } else {
      // POST
      let canvasJson = this.canvas.toJSON([
        "width",
        "height",
        "device",
        "deviceUUID",
        "name",
        "selectedType",
        "selectedProperty",
        "zoom",
      ]);
      let checkBackgroundImgResult = await this.checkBackgroundImg(canvasJson);
      canvasJson.canvasScaleType = this.canvasScaleType;
      canvasJson.zoom = this.canvas.getZoom();
      const saveJSON = JSON.stringify(canvasJson);
      if (checkBackgroundImgResult) {
        apiPostPlantFloor({
          canvas: saveJSON,
          intervalTime:
            this.props.intervalTime === null ? 30 : this.props.intervalTime,
          topologyType: this.props.topologyType,
          topologyUuid: this.props.topologyUuid,
        })
          .then((res) => {
            setTimeout(msg, 0);
            if (res.status.toString() === "200") {
              this.saveSuccess(res.data.uuid); // show success modal
              window.history.pushState(
                {},
                0,
                window.location.origin + "/setting/" + res.data.uuid
              );
            } else {
              message.error(
                this.context.formatMessage({
                  id: "setting.canvas.saveFailed",
                })
              );
            }
          })
          .catch((err) => {
            if (err.message === "Network Error") {
              // 圖檔太大無法儲存
              setTimeout(msg, 0);
              message.error(
                this.context.formatMessage({
                  id: "setting.canvas.saveFailed",
                }) +
                  this.context.formatMessage({
                    id: "setting.canvas.overFileSizeLimit",
                  })
              );
              return;
            }
            if (err.response.status.toString() === "401") {
              // token過期
              setTimeout(msg, 0);
              message.error(
                this.context.formatMessage({
                  id: "setting.canvas.networkAbnormal",
                })
              );
            }
            if (err.response.status.toString() === "500") {
              // 儲存失敗
              setTimeout(msg, 0);
              message.error(
                this.context.formatMessage({
                  id: "setting.canvas.saveFailed",
                })
              );
            }
          })
          .then(() => {
            this.saveLoading = false;
          });
        const msg = message.loading(
          this.context.formatMessage({
            id: "setting.canvas.saving",
          }),
          0
        );
      } else {
        message.error(
          this.context.formatMessage({
            id: "setting.canvas.backgroundImgSaveFailed",
          })
        );
        this.saveLoading = false;
      }
    }
  };

  checkBackgroundImg = async (canvasJson) => {
    let result = true;
    if (this.hasBackgroundImage) {
      // 目前有背景圖
      for (const obj of canvasJson.objects) {
        if (obj.hasOwnProperty("name") && obj.name === "backgroundImg") {
          if (obj.src.substr(0, 10) === "data:image") {
            if (this.originalBackgroundImgSrc !== null) {
              // 原本有圖片
              // 先把舊的刪除
              try {
                await apiDeleteFile(this.originalBackgroundImgSrc);
              } catch (e) {
                // 刪除失敗
              }
            }

            // 更新背景圖 => put
            try {
              let filePath = `/floorplan/backgroundImg/${uuidv4()}`;
              let newBackgroundImg = await apiNewFile({
                path: filePath,
                dataURL: obj.src,
              });

              // 要存的東西要放 filepath = newBackgroundImg
              obj.src = newBackgroundImg.path;
              this.originalBackgroundImgSrc = newBackgroundImg.path;
            } catch (e) {
              // 新增失敗
              result = false;
            }
          } else {
            // 沒有新增或更改背景圖
            obj.src = this.originalBackgroundImgSrc;
          }
        }
        break;
      }
    } else {
      // 目前沒背景圖
      if (this.originalBackgroundImgSrc !== null) {
        // 原本有圖片
        // 把原本的圖片刪除
        try {
          await apiDeleteFile(this.originalBackgroundImgSrc);
          this.originalBackgroundImgSrc = null;
        } catch (e) {
          // 刪除失敗
        }
      }
    }
    return result;
  };

  saveSuccess = (uuid) => {
    Modal.success({
      width: window.innerWidth < 768 ? "96vw" : "450px",
      title: this.context.formatMessage({
        id: "setting.canvas.saveSuccess",
      }),
      content: (
        <Steps direction="vertical" size="small">
          <Step
            status="process"
            title={this.context.formatMessage({
              id: "setting.canvas.saveSuccessDiscriptionStep1",
            })}
            description={
              <div
                className="copyCanvasUrl"
                onClick={() => {
                  let result = copy(window.location.origin + "/view/" + uuid, {
                    format: "text/plain",
                  });
                  if (result === true) {
                    message.success(
                      this.context.formatMessage({
                        id: "setting.canvas.copySuccess",
                      })
                    );
                  }
                }}
              >
                {window.location.origin + "/view/" + uuid}
              </div>
            }
          />

          <Step
            status="process"
            title={
              <div>
                {this.context.formatMessage({
                  id: "setting.canvas.saveSuccessDiscriptionStep2",
                })}
                <Tag color="blue" style={{ margin: "5px", fontSize: "15px" }}>
                  {this.context.formatMessage({
                    id: "setting.canvas.editEmbeddedUrl",
                  })}
                </Tag>
              </div>
            }
          />

          <Step
            status="process"
            title={
              <div>
                {this.context.formatMessage({
                  id: "setting.canvas.saveSuccessDiscriptionStep3",
                })}
              </div>
            }
          />
        </Steps>
      ),
    });
  };

  showToolBar = () => {
    this.canvas.getActiveObject();
    this.props.setShowToolBar(true);
  };

  getAlarmCode = (uuid) => {
    let params = {
      deviceUUID: uuid,
      from: dayjs().subtract(1, "minute").toISOString(),
      to: dayjs().toISOString(),
    };
    apiGetAlarmCode(params).then((response) => {
      if (response.length > 0) {
        this.setState({
          cardExtra: (
            <div style={{ fontWeight: "bold", color: statusColors[3] }}>
              <div>
                <AlertOutlined /> {response[0].errCode}
              </div>
              <div>{response[0].messageTc}</div>
            </div>
          ),
        });
      } else {
        this.setState({ cardExtra: null });
      }
    });
  };

  render() {
    return (
      <div>
        <canvas id="meCanvas" ref="myFabric" />
        <div id="tooltip" style={{ display: "none" }}>
          <Card
            title={this.state.cardTitle}
            style={{ width: 350 }}
            bodyStyle={{ padding: "10px" }}
            extra={this.state.cardExtra}
          >
            {this.state.cardContent}
          </Card>
        </div>
      </div>
    );
  }
}

Canvas.contextType = IntlContext;
export default Canvas;
