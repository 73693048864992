import { LOCALES } from "../../constants";
import view from "./view";
import setting from "./setting";
import tooltip from "./tooltip";

const obj = {
  [LOCALES.ENGLISH]: {
    ...view,
    ...setting,
    ...tooltip,
  },
};

export default obj;
