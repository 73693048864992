import React, { useState, useEffect, useCallback } from "react";
import { canvasScale } from "../KeyMap";
import {
  Upload,
  Button,
  InputNumber,
  Popover,
  Select,
  notification,
} from "antd";
import { UndoOutlined, RedoOutlined } from "@ant-design/icons";
import { TwitterPicker } from "react-color";
import ToolbarButton from "./ToolBarButton";
import { FormattedMessage, useIntl } from "react-intl";

const { Option } = Select;

const Toolbar = (props) => {
  const { formatMessage } = useIntl();

  const [editBackgroundImg, setEditBackgroundImg] = useState(false);
  const fileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        props.setUploadPicture(e.target.result);
      } catch (error) {}
    };

    reader.readAsDataURL(file);
    return false;
  };

  const rotateItem = useCallback(() => {
    return (
      <>
        <Button
          style={{ marginRight: "2px", marginBottom: "2px" }}
          onClick={() => {
            props.canvasRef.leftRotate(); // 向左旋轉90度
          }}
        >
          <UndoOutlined /> {formatMessage({ id: "setting.leftRotate" })}
        </Button>

        <Button
          style={{ marginRight: "2px", marginBottom: "2px" }}
          onClick={() => {
            props.canvasRef.rightRotate(); // 向右旋轉90度
          }}
        >
          <RedoOutlined /> {formatMessage({ id: "setting.rightRotate" })}
        </Button>
      </>
    );
  }, [props.canvasRef, formatMessage]);

  useEffect(() => {
    if (props.showToolBar) {
      notification.open({
        key: "toolBarButton",
        message: null,
        description: (
          <>
            <ToolbarButton
              canvasRef={props.canvasRef}
              formatMessage={formatMessage}
            />
            {rotateItem()}
          </>
        ),
        maxCount: 1,
        duration: null, // 自動關閉
        top: "34px",
        style: { width: 250, position: "fixed", right: 0 },
        closeIcon: <div style={{ display: "none" }}></div>,
        onClose: props.setShowToolBarFalse,
      });
    } else {
      notification.close("toolBarButton");
    }
  }, [
    props.showToolBar,
    props.canvasRef,
    props.setShowToolBarFalse,
    rotateItem,
    formatMessage,
  ]);

  return (
    <div
      style={{
        display: "flex",
        paddingRight: "20px",
        paddingTop: "1px",
        paddingBottom: "1px",
      }}
    >
      {editBackgroundImg === true ? (
        <>
          {rotateItem()}

          <Button
            style={{ marginLeft: "2px" }}
            danger
            onClick={() => {
              setEditBackgroundImg(false);
              props.canvasRef.cancelEditBackgroundImg();
            }}
          >
            <FormattedMessage id="setting.cancelEditBackgroundImg" />
          </Button>

          <Button
            style={{ marginLeft: "2px" }}
            type="primary"
            onClick={() => {
              setEditBackgroundImg(false);
              props.canvasRef.saveBackgroundImg();
            }}
          >
            <FormattedMessage id="setting.saveEditBackgroundImg" />
          </Button>
        </>
      ) : (
        <>
          <Select
            style={{ marginLeft: "2px", marginRight: "2px" }}
            onChange={(value) => {
              props.setCanvasScaleType(value);
            }}
            value={props.canvasScaleType}
          >
            {canvasScale.map((item, key) => (
              <Option key={key} value={item.scale}>
                <FormattedMessage id={`setting.canvasScale.${item.type}`} />
              </Option>
            ))}
          </Select>
          <Upload
            name="file"
            accept=".jpg, .jpeg, .png"
            beforeUpload={fileUpload}
            maxCount={1}
            showUploadList={false}
          >
            <Button type="primary" ghost style={{ marginRight: "2px" }}>
              {props.uploadPicture === null ? (
                <FormattedMessage id="setting.uploadBackgroundImg" />
              ) : (
                <FormattedMessage id="setting.resetUploadBackgroundImg" />
              )}
            </Button>
          </Upload>

          {props.uploadPicture === null ? null : (
            <>
              <Button
                type="primary"
                style={{ marginRight: "2px" }}
                onClick={() => {
                  setEditBackgroundImg(true);
                  props.canvasRef.editBackgroundImg();
                }}
              >
                <FormattedMessage id="setting.editBackgroundImg" />
              </Button>

              <Button
                danger
                style={{ marginRight: "2px" }}
                onClick={() => props.setUploadPicture(null)}
              >
                <FormattedMessage id="setting.removeBackgroundImg" />
              </Button>
            </>
          )}

          <Popover
            content={
              <TwitterPicker
                styles={{
                  default: {
                    swatch: {
                      border: "1px solid #F0F0F0",
                    },
                  },
                }}
                backgroundColor="red"
                triangle="hide"
                colors={["#FFFFFF", "#F0F0F0", "#C4E5F6"]}
                color={props.canvasBackgroundColor}
                onChange={(color) => props.setCanvasBackgroundColor(color.hex)}
              />
            }
          >
            <Button>
              <FormattedMessage id="setting.backgroundColor" />
            </Button>
          </Popover>

          <Button
            type="primary"
            ghost
            style={{ marginRight: "2px" }}
            onClick={() => {
              props.setAddAndEditModalVisible(true);
            }}
          >
            <FormattedMessage id="setting.addSymbol" />
          </Button>

          <Button
            type="primary"
            style={{ marginRight: "2px" }}
            onClick={() => {
              props.canvasRef.saveCanvas();
            }}
          >
            <FormattedMessage id="setting.save" />
          </Button>

          <div>
            <FormattedMessage id="setting.updateTime" />
            <InputNumber
              min={30}
              max={300}
              defaultValue={props.intervalTime}
              onChange={(value) => props.setIntervalTime(value)}
            />
            <FormattedMessage id="setting.second" />
          </div>
        </>
      )}
    </div>
  );
};

export default Toolbar;
