import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { message } from "antd";
import Toolbar from "./Toolbar";
import Canvas from "../Canvas";
import AddAndEditModal from "./AddAndEditModal";
import { apiGetTopology, apiGetPlantFloor } from "../../api/topology";
import NoPermission from "../NoPermission";
import { PermissionContext } from "../../App";
import queryString from "query-string";
import { useIntl } from "react-intl";

const Setting = () => {
  const { formatMessage } = useIntl();

  const [uploadPicture, setUploadPicture] = useState(null);
  const [addAndEditModalVisible, setAddAndEditModalVisible] = useState(false);
  const [deviceData, setDeviceData] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [getEditItem, setGetEditItem] = useState(null);
  const [plantFloorCanvas, setPlantFloorCanvas] = useState(null);
  const [initOK, setInitOK] = useState(false);
  const [canvasInitOk, setCanvasInitOk] = useState(false);
  const canvasRef = useRef(null);
  const [intervalTime, setIntervalTime] = useState(30);
  const [permission, setPermission] = useState("loding");
  const permissionContext = useContext(PermissionContext);
  const [canvasBackgroundColor, setCanvasBackgroundColor] = useState("#C4E5F6");
  const [showToolBar, setShowToolBar] = useState(false);
  const [canvasScaleType, setCanvasScaleType] = useState(1);
  const [initialCascaderValue, setInitialCascaderValue] = useState([]);
  const topologyType = useRef("");
  const topologyUuid = useRef("");

  const processLine = useCallback(
    (group, factory, source) => {
      return source.map((line) => {
        let initialCascaserValue = null;

        let data = Object.assign({}, line, { children: [] });
        if (line.children.length > 0) {
          data.children = line.children.sort(compareName);
        }

        if (line.children.length === 0) {
          // 產線底下沒有設備
          data.isLeaf = true;
          data.disabled = true;

          initialCascaserValue = [group.uuid, factory.uuid];
        } else {
          initialCascaserValue = [group.uuid, factory.uuid, line.uuid];
        }

        if (
          topologyType.current === "line" &&
          topologyUuid.current === line.uuid
        ) {
          if (initialCascaserValue.length === 2) {
            // means choose this line, but not has device under this line
            message.warning(formatMessage({ id: "setting.noDeviceUnderLine" }));
          }
          setInitialCascaderValue(initialCascaserValue);
        }

        return data;
      });
    },
    [formatMessage]
  );

  const processFactory = useCallback(
    (group, source) => {
      return source.map((factory) => {
        let initialCascaserValue = null;

        let data = Object.assign({}, factory, { children: [] });
        if (factory.children.length > 0) {
          data.children = processLine(
            group,
            factory,
            factory.children.sort(compareName)
          );
        }

        if (factory.children.length === 0) {
          // 工廠底下沒有產線
          data.isLeaf = true;
          data.disabled = true;

          initialCascaserValue = [group.uuid];
        } else {
          initialCascaserValue = [group.uuid, factory.uuid];
        }

        if (
          topologyType.current === "factory" &&
          topologyUuid.current === factory.uuid
        ) {
          if (initialCascaserValue.length === 1) {
            // means choose this factory, but not has line under this factory
            message.warning(
              formatMessage({ id: "setting.noLineUnderFactory" })
            );
          }
          setInitialCascaderValue(initialCascaserValue);
        }

        return data;
      });
    },
    [processLine, formatMessage]
  );

  const processGroup = useCallback(
    (source) => {
      return source.map((group) => {
        let data = Object.assign({}, group, { children: [] });
        if (group.uuid !== "-1" && group.children.length > 0) {
          data.children = processFactory(
            group,
            group.children.sort(compareName)
          );
        } else {
          data.children = group.children.sort(compareName);
        }

        if (group.children.length === 0) {
          // 群組底下沒有工廠
          data.isLeaf = true;
          data.disabled = true;
        }
        return data;
      });
    },
    [processFactory]
  );

  const findOptionToDisabled = useCallback((allOptions, deviceUUID) => {
    try {
      if (deviceUUID.length === 2) {
        let group = allOptions.findIndex((item) => item.uuid === deviceUUID[0]);
        let device = allOptions[group].children.findIndex(
          (item) => item.uuid === deviceUUID[1]
        );
        return device === -1 ? null : [group, device];
      } else {
        let group = allOptions.findIndex((item) => item.uuid === deviceUUID[0]);
        let factory = allOptions[group].children.findIndex(
          (item) => item.uuid === deviceUUID[1]
        );
        let line = allOptions[group].children[factory].children.findIndex(
          (item) => item.uuid === deviceUUID[2]
        );
        let device = allOptions[group].children[factory].children[
          line
        ].children.findIndex((item) => item.uuid === deviceUUID[3]);
        return device === -1 ? null : [group, factory, line, device];
      }
    } catch (e) {
      return null;
    }
  }, []);

  const setNewDeviceOption = (deviceUUID, disabled) => {
    // deviceUUID,  disabled
    let allOptions = Array.from(deviceData);
    let optionIndex = findOptionToDisabled(allOptions, deviceUUID);
    if (optionIndex !== null) {
      if (optionIndex.length === 2) {
        allOptions[optionIndex[0]].children[optionIndex[1]].disabled = disabled;
      } else {
        allOptions[optionIndex[0]].children[optionIndex[1]].children[
          optionIndex[2]
        ].children[optionIndex[3]].disabled = disabled;
      }
    }
    setDeviceData(allOptions);
  };

  const newDeviceData = useCallback(
    (data) => {
      let canvasData = null;
      try {
        if (data.plantFloorCanvas !== "") {
          canvasData = JSON.parse(data.plantFloorCanvas);
        }
      } catch (e) {
        console.log("Setting - newDeviceData - canvasData", e);
      }
      let canvasObj = [];
      try {
        if (canvasData !== null && canvasData.hasOwnProperty("objects")) {
          canvasObj = canvasData.objects;
        }
      } catch (e) {
        console.log("Setting - newDeviceData - canvasObj", e);
      }
      let allOptions = Array.from(data.optionData);
      for (const obj of canvasObj) {
        if (obj.hasOwnProperty("deviceUUID")) {
          let optionIndex = findOptionToDisabled(allOptions, obj.deviceUUID);
          if (optionIndex !== null) {
            if (optionIndex.length === 2) {
              allOptions[optionIndex[0]].children[
                optionIndex[1]
              ].disabled = true;
            } else {
              allOptions[optionIndex[0]].children[optionIndex[1]].children[
                optionIndex[2]
              ].children[optionIndex[3]].disabled = true;
            }
          }
        }
      }
      setDeviceData(allOptions);
    },
    [findOptionToDisabled]
  );

  useEffect(() => {
    if (
      permissionContext.read !== false &&
      permissionContext.update !== false
    ) {
      // 有topology read update 權限
      const parameters = queryString.parse(window.location.search);
      if (
        parameters.topologyType !== null &&
        parameters.topologyType !== undefined &&
        parameters.topologyType !== ""
      ) {
        if (
          parameters.topologyUuid !== null &&
          parameters.topologyUuid !== undefined &&
          parameters.topologyUuid !== ""
        ) {
          topologyType.current = parameters.topologyType;
          topologyUuid.current = parameters.topologyUuid;
        }
      }

      apiGetTopology()
        .then((topologyRes) => {
          const str = window.location.pathname;
          let path = str.split("/");

          if (
            (path[1] === "setting" &&
              path[2] !== undefined &&
              path[2] !== "") ||
            path[1] === "view"
          ) {
            // setting/uuid
            apiGetPlantFloor(path[2])
              .then((res) => {
                if (
                  res.topologyType &&
                  res.topologyUuid &&
                  res.topologyType !== "" &&
                  res.topologyUuid !== ""
                ) {
                  topologyType.current = res.topologyType;
                  topologyUuid.current = res.topologyUuid;
                }

                // process cascader options
                let options = JSON.parse(
                  JSON.stringify(topologyRes.allTopology)
                );
                if (topologyRes.devicesWithoutLine.length > 0) {
                  options.push({
                    uuid: "-1",
                    name: formatMessage({ id: "setting.devicesWithoutLine" }),
                    children: Array.from(
                      topologyRes.devicesWithoutLine,
                      (item) => item
                    ),
                  });
                }
                options.sort(sortGroup);
                let optionData = processGroup(options);
                newDeviceData({ optionData, plantFloorCanvas: res.canvas });
                if (res.canvas !== "") {
                  setPlantFloorCanvas(res.canvas);
                  setCanvasScaleType(JSON.parse(res.canvas).canvasScaleType);
                  setCanvasBackgroundColor(JSON.parse(res.canvas).background);
                  if (res.intervalTime !== 0) {
                    setIntervalTime(res.intervalTime);
                  }
                }
                setInitOK(true);
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status.toString() === "401") {
                    // 無topology read權限
                    setPermission("noReadWritePromission");
                  } else if (err.response.status.toString() === "500") {
                    setPermission("noMatchCanvasUuid");
                  }
                }
              });
          } else {
            // process cascader options
            let options = JSON.parse(JSON.stringify(topologyRes.allTopology));
            if (topologyRes.devicesWithoutLine.length > 0) {
              options.push({
                uuid: "-1",
                name: formatMessage({ id: "setting.devicesWithoutLine" }),
                children: Array.from(
                  topologyRes.devicesWithoutLine,
                  (item) => item
                ),
              });
            }
            options.sort(sortGroup);

            let optionData = processGroup(options);
            setDeviceData(optionData);
            setInitOK(true);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status.toString() === "401") {
            // 無topology read權限
            setPermission("noReadWritePromission");
          }
        });
    } else {
      // 無topology read write權限
      setPermission("noReadWritePromission");
    }
  }, [processGroup, newDeviceData, permissionContext, formatMessage]);

  const sortGroup = (a, b) => {
    if (a.uuid === "-1") {
      return 1;
    } else if (b.uuid === "-1") {
      return -1;
    } else {
      return a.name > b.name ? 1 : -1;
    }
  };

  function compareName(a, b) {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  }

  const addHandleOk = (value) => {
    setAddAndEditModalVisible(false);
    setUpdateFlag(!updateFlag);
    setNewDeviceOption(value, true); // deviceUUID, disabled
  };

  const setGetEditItemAndSetNewDeviceOptions = (newItem) => {
    if (
      newItem !== null &&
      newItem.hasOwnProperty("device") &&
      newItem.hasOwnProperty("deviceUUID")
    ) {
      setNewDeviceOption(newItem.deviceUUID, false); // edit進來時把 device disabled 設成 false
    }
    setGetEditItem(newItem);
  };

  return initOK === true ? (
    <>
      <Toolbar
        setUploadPicture={setUploadPicture}
        uploadPicture={uploadPicture}
        setAddAndEditModalVisible={setAddAndEditModalVisible}
        canvasRef={canvasRef.current}
        setIntervalTime={setIntervalTime}
        intervalTime={intervalTime}
        setCanvasBackgroundColor={setCanvasBackgroundColor}
        canvasBackgroundColor={canvasBackgroundColor}
        showToolBar={showToolBar}
        setShowToolBarFalse={() => setShowToolBar(false)}
        setCanvasScaleType={setCanvasScaleType}
        canvasScaleType={canvasScaleType}
        canvasInitOk={canvasInitOk}
      />

      <AddAndEditModal
        initialCascaderValue={initialCascaderValue}
        addAndEditModalVisible={addAndEditModalVisible}
        deviceData={deviceData}
        setAddAndEditModalVisible={setAddAndEditModalVisible}
        addHandleOk={addHandleOk}
        canvasRef={canvasRef.current}
        getEditItem={getEditItem}
        setGetEditItem={setGetEditItemAndSetNewDeviceOptions}
        setNewDeviceOption={setNewDeviceOption}
      />

      <Canvas
        topologyType={topologyType.current}
        topologyUuid={topologyUuid.current}
        setUploadPicture={setUploadPicture}
        uploadPicture={uploadPicture}
        updateFlag={updateFlag}
        setGetEditItem={setGetEditItemAndSetNewDeviceOptions}
        ref={canvasRef}
        plantFloorCanvas={plantFloorCanvas}
        setNewDeviceOption={setNewDeviceOption}
        setUpdateFlag={() => setUpdateFlag(!updateFlag)}
        intervalTime={intervalTime}
        canvasBackgroundColor={canvasBackgroundColor}
        setAddAndEditModalVisible={setAddAndEditModalVisible}
        setShowToolBar={setShowToolBar}
        canvasScaleType={canvasScaleType}
        setCanvasInitOk={setCanvasInitOk}
      />
    </>
  ) : (
    <NoPermission permission={permission} />
  );
};

export default Setting;
