import React, { useState, useRef, useEffect } from "react";
import {
  Cascader,
  Modal,
  Select,
  Button,
  Upload,
  Tag,
  Input,
  Popover,
} from "antd";
import {
  UploadOutlined,
  FontColorsOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";
import { symbol } from "./Symbol";
import AddAndEditCanvas from "./AddAndEditCanvas";
import Resizer from "react-image-file-resizer";
import { fabric } from "fabric";
import { CompactPicker } from "react-color";
import { FormattedMessage, useIntl } from "react-intl";

const { Option } = Select;

const AddAndEditModal = (props) => {
  const { formatMessage } = useIntl();
  const [currentSelectLine, setCurrentSelectLine] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState({
    uri: null,
    name: null,
  }); // device - 選擇的圖標
  const [selectedSymbolPos, setSelectedSymbolPos] = useState({
    left: 0,
    top: 50,
  }); // device
  const selectedValue = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null); // device - 選擇的設備
  const [selectedDevicePos, setSelectedDevicePos] = useState({
    left: 0,
    top: 0,
  }); // device
  const addAndEditCanvasRef = useRef(null);
  const originEditDeviceRef = useRef(null);
  const [objectOrder, setObjectOrder] = useState([]);
  const scaleFactorRef = useRef(1);
  const originalAngleRef = useRef(0);
  const originalOriginXRef = useRef("");
  const originalOriginYRef = useRef("");
  const [selectedType, setSelectedType] = useState("device"); // 插入的類型 device / word / deviceType
  const [inputValue, setInputValue] = useState(""); // word
  const [wordExtraProperties, setWordExtraProperties] = useState({
    color: "black",
    backgroundColor: "TRANSPARENT",
  }); // word's property
  const [showType, setShowType] = useState("factory"); // deviceType - factory / line
  // const showTypeValue = useRef(null);
  const [currentSelectFactoryOrLine, setCurrentSelectFactoryOrLine] =
    useState(null); // deviceType - 目前選的工廠或產線
  const [selectedFactoryOrLine, setSelectedFactoryOrLine] = useState([]); // deviceType - 選擇的工廠或產線 uuid
  // const selectedFactoryOrLineValue = useRef(null);
  const [selectedFactoryOrLineStatus, setSelectedFactoryOrLineStatus] =
    useState([]);

  const getSelectDevice = (value, selectedOptions) => {
    if ((value.length === 2 && value[0] === "-1") || value.length === 4) {
      setSelectedDevice(
        Object.assign(
          {},
          { device: selectedOptions[selectedOptions.length - 1] },
          { deviceUUID: value }
        )
      );
      setCurrentSelectLine(value);
      selectedValue.current = value;
      setObjectOrder(["text"]);
    }
    if (value.length === 0) {
      setSelectedDevice(null);
      setCurrentSelectLine([]);
      selectedValue.current = null;
    }
  };

  const fileUpload = (file) => {
    return new Promise((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          // resize
          file, // file
          200, // maxWidth
          200, // maxHeight
          "png", // compressFormat
          100, // quality
          0, // rotation
          (uri) => {
            // responseUriFunc
            // setSelectedSymbol(uri);
            setSelectedSymbol({ uri: uri, name: file.name });
            setObjectOrder(["image"]);
            resolve(false);
          },
          "base64" // outputType
          // 200, // minWidth
          // 200 // minHeight
        );
      } catch (err) {
        console.log("Setting - fileUpload", err);
        reject("Error");
      }
    });
  };

  const reset = () => {
    // device
    setSelectedSymbol({ uri: null, name: null });
    setSelectedSymbolPos({ left: 0, top: 50 });
    setSelectedDevice(null);
    setSelectedDevicePos({ left: 0, top: 0 });
    setObjectOrder([]);
    // word
    setInputValue("");
    setWordExtraProperties({ color: "black", backgroundColor: "TRANSPARENT" });
    // deviceStatus
    setShowType("factory");
    setSelectedFactoryOrLine(null);
    setSelectedFactoryOrLineStatus([]);
    setCurrentSelectFactoryOrLine(null);
    if (selectedValue.current !== null) {
      let newValue = Array.from(currentSelectLine);
      newValue.pop();
      setCurrentSelectLine(newValue);
      selectedValue.current = null;
    }

    // if (selectedFactoryOrLineValue.current !== null) {
    //   console.log('in!!!')
    //   let newValue = Array.from(currentSelectFactoryOrLine);
    //   newValue.pop();
    //   setCurrentSelectFactoryOrLine(newValue);
    //   setShowType();
    //   showTypeValue
    //   selectedFactoryOrLineValue.current = null;
    // }
  };

  useEffect(() => {
    let tmpObjectToCanvas = [];
    let selectedType = "";
    if (props.getEditItem !== null) {
      selectedType = props.getEditItem.hasOwnProperty("selectedType")
        ? props.getEditItem.selectedType
        : "device";
      if (selectedType === "device") {
        if (props.getEditItem.type === "group") {
          // group = image + text
          fabric.util.enlivenObjects(
            props.getEditItem.toObject(["device", "deviceUUID", "name"])
              .objects,
            (objects) => {
              scaleFactorRef.current = scaleObject(props.getEditItem);
              objects.forEach((item) => {
                item.set({
                  left:
                    (item.left + props.getEditItem.width / 2) *
                    scaleFactorRef.current,
                  top:
                    (item.top + props.getEditItem.height / 2) *
                    scaleFactorRef.current,
                });

                if (item.type === "image") {
                  item.set({
                    scaleX: item.scaleX * scaleFactorRef.current,
                    scaleY: item.scaleY * scaleFactorRef.current,
                  });
                  setEditImage(item, props.getEditItem.type);
                  tmpObjectToCanvas.push("image");
                }

                if (item.type === "text") {
                  item.scale(
                    scaleFactorRef.current * item.getObjectScaling().scaleX
                  );
                  setEditText(item, props.getEditItem.type);
                  tmpObjectToCanvas.push("text");
                }
              });
              setObjectOrder(tmpObjectToCanvas);
            }
          );
        } else if (props.getEditItem.type === "image") {
          //image
          setEditImage(props.getEditItem, props.getEditItem.type);
          tmpObjectToCanvas.push("image");
          setObjectOrder(tmpObjectToCanvas);
        } else {
          // text
          setEditText(props.getEditItem, props.getEditItem.type);
          tmpObjectToCanvas.push("text");
          setObjectOrder(tmpObjectToCanvas);
          // console.log('selectedType', selectedType)
        }
      } else if (selectedType === "word") {
        setInputValue(props.getEditItem.text);
        setWordExtraProperties({
          color: props.getEditItem.fill,
          backgroundColor: props.getEditItem.backgroundColor,
        });
      } else if (selectedType === "deviceStatus") {
        let factoryOrLineStatus = [];
        fabric.util.enlivenObjects(
          props.getEditItem.toObject(["device", "deviceUUID", "name"]).objects,
          (objects) => {
            scaleFactorRef.current = scaleObject(props.getEditItem);
            objects.forEach((item) => {
              item.set({
                left:
                  (item.left + props.getEditItem.width / 2) *
                  scaleFactorRef.current,
                top:
                  (item.top + props.getEditItem.height / 2) *
                  scaleFactorRef.current,
              });
              factoryOrLineStatus.push(item);
            });
          }
        );
        setSelectedFactoryOrLineStatus(factoryOrLineStatus);
        setCurrentSelectFactoryOrLine(props.getEditItem.selectedProperty.uuid);
        setShowType(props.getEditItem.selectedProperty.type);
      }
      originalAngleRef.current = props.getEditItem.angle;
      originalOriginXRef.current = props.getEditItem.originX;
      originalOriginYRef.current = props.getEditItem.originY;
      setSelectedType(selectedType);
    }
    //  eslint-disable-next-line
  }, [props.getEditItem]);

  useEffect(() => {
    setCurrentSelectLine(props.initialCascaderValue);
  }, [props.initialCascaderValue]);

  const scaleObject = (obj) => {
    let objWidth = obj.width;
    let objHeight = obj.height;
    let objAspect = objWidth / objHeight;
    let max = 300; // 最大寬高
    let scaleFactor = 1;

    if (objAspect >= 1) {
      // 橫向圖片
      scaleFactor = objWidth > max ? max / objWidth : 1;
    } else {
      // 直向圖片
      scaleFactor = objHeight > max ? max / objHeight : 1;
    }
    return scaleFactor;
  };

  const setEditImage = (item, type) => {
    setSelectedSymbolPos({
      left: type === "group" ? item.left : 0,
      top: type === "group" ? item.top : 50,
    });
    setSelectedSymbol({
      uri: item.src,
      name: item.name,
      scaleX: type === "group" ? item.scaleX : 1,
      scaleY: type === "group" ? item.scaleY : 1,
    });
  };

  const setEditText = (item, type) => {
    setSelectedDevicePos({
      left: type === "group" ? item.left : 0,
      top: type === "group" ? item.top : 0,
    });
    if (item.hasOwnProperty("device") && item.hasOwnProperty("deviceUUID")) {
      setSelectedDevice({
        device: item.device,
        deviceUUID: item.deviceUUID,
        scaleX: type === "group" ? item.scaleX : 1,
        scaleY: type === "group" ? item.scaleY : 1,
      });
      setCurrentSelectLine(item.deviceUUID);
      selectedValue.current = item.deviceUUID;
      originEditDeviceRef.current = item.deviceUUID;
    } else {
      setSelectedDevice({
        device: { name: formatMessage({ id: "setting.canvas.noDevice" }) },
        deviceUUID: [],
        scaleX: type === "group" ? item.scaleX : 1,
        scaleY: type === "group" ? item.scaleY : 1,
      });
    }
  };

  const handleChangeSelectedType = (value) => {
    setSelectedType(value);
    reset();
  };

  const getInputWord = (e) => {
    setInputValue(e.target.value);
  };

  const addDevice = () => {
    return (
      <>
        <div className="add-device-left">
          <FormattedMessage id="setting.deviceName" />
        </div>
        <div className="add-device-right">
          <Cascader
            placeholder={formatMessage({ id: "setting.selectDevice" })}
            options={props.deviceData}
            fieldNames={{ label: "name", value: "uuid", children: "children" }}
            allowClear={true}
            onChange={getSelectDevice}
            style={{ width: "100%" }}
            value={currentSelectLine}
          />
        </div>

        <div className="add-device-left">
          <FormattedMessage id="setting.symbolType" />
        </div>
        <div
          className="add-device-right"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Select
            placeholder={<FormattedMessage id="setting.selectSymbol" />}
            style={{ width: "calc(100% - 120px)", marginRight: "5px" }}
            onChange={(value, option) => {
              setSelectedSymbol(
                option === undefined
                  ? { uri: null, name: null }
                  : { uri: value, name: option.children.props.children[0] }
              );
              setObjectOrder(["image"]);
            }}
            allowClear={true}
            value={selectedSymbol.name}
          >
            {symbol.map((item, key) => (
              <Option key={key} value={item.url}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormattedMessage id={`setting.symbol.${item.name}`} />
                  <img
                    src={item.url}
                    alt="src"
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
              </Option>
            ))}
          </Select>

          <> / </>

          <Upload
            name="file"
            accept=".jpg, .jpeg, .png"
            beforeUpload={(file) => {
              setLoading(true);
              setSelectedSymbol({ uri: null, name: null });
              fileUpload(file).then(() => {
                setLoading(false);
              });
              return false;
            }}
            maxCount={1}
            showUploadList={false}
          >
            <Button
              icon={<UploadOutlined />}
              style={{ marginLeft: "5px" }}
              loading={loading}
            >
              {" "}
              <FormattedMessage id="setting.uploadSymbol" />
            </Button>
          </Upload>
        </div>
      </>
    );
  };

  const addWord = () => {
    return (
      <>
        <div className="add-device-left">
          <FormattedMessage id="setting.inputWord" />
        </div>
        <div
          className="add-device-right"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Input value={inputValue} onChange={(e) => getInputWord(e)} />
        </div>

        <div className="add-device-left"></div>
        <div
          className="add-device-right"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Popover
            placement="bottom"
            content={
              <div style={{ display: "block" }}>
                <div
                  className="wordColorButton"
                  onClick={() =>
                    setWordExtraProperties(
                      Object.assign({}, wordExtraProperties, {
                        color: "#000000",
                      })
                    )
                  }
                >
                  <FormattedMessage id="setting.auto" />
                </div>
                <CompactPicker
                  styles={{
                    default: {
                      swatch: {
                        border: "1px solid #F0F0F0",
                      },
                    },
                  }}
                  color={wordExtraProperties.color}
                  onChange={(color) =>
                    setWordExtraProperties(
                      Object.assign({}, wordExtraProperties, {
                        color: color.hex,
                      })
                    )
                  }
                />
              </div>
            }
          >
            <Button style={{ marginRight: "2px" }}>
              <FontColorsOutlined /> <FormattedMessage id="setting.wordColor" />
            </Button>
          </Popover>

          <Popover
            placement="bottom"
            content={
              <div style={{ display: "block" }}>
                <div
                  className="wordColorButton"
                  onClick={() =>
                    setWordExtraProperties(
                      Object.assign({}, wordExtraProperties, {
                        backgroundColor: "TRANSPARENT",
                      })
                    )
                  }
                >
                  <FormattedMessage id="setting.notFilled" />
                </div>
                <CompactPicker
                  styles={{
                    default: {
                      swatch: {
                        border: "1px solid #F0F0F0",
                      },
                    },
                  }}
                  color={wordExtraProperties.backgroundColor}
                  onChange={(color) =>
                    setWordExtraProperties(
                      Object.assign({}, wordExtraProperties, {
                        backgroundColor: color.hex,
                      })
                    )
                  }
                />
              </div>
            }
          >
            <Button style={{ marginRight: "2px" }}>
              <BgColorsOutlined /> <FormattedMessage id="setting.shading" />
            </Button>
          </Popover>
        </div>
      </>
    );
  };

  const getSelectFactoryAndLine = async (value, selectedOptions) => {
    if (
      (showType === "factory" && value.length === 2) ||
      (showType === "line" && value.length === 3)
    ) {
      setSelectedFactoryOrLine(
        Object.assign(
          {},
          { factoryOrLine: selectedOptions[selectedOptions.length - 1] },
          { factoryOrLineUUID: value },
          { type: showType }
        )
      );
      setCurrentSelectFactoryOrLine(value);
      // selectedFactoryOrLineValue.current = value;
    }
    if (value.length === 0) {
      setSelectedFactoryOrLine(null);
      setCurrentSelectFactoryOrLine("");
      // selectedFactoryOrLineValue.current = null;
    }
  };

  const addDeviceStatus = () => {
    let newData = JSON.parse(JSON.stringify(props.deviceData));
    if (newData.length > 0) {
      if (newData[newData.length - 1].uuid === "-1") {
        newData.pop();
      }

      for (const group of newData) {
        if (group.children.length !== 0) {
          for (const factory of group.children) {
            if (factory.children.length !== 0) {
              if (showType === "factory") {
                factory.children = [];
              } else {
                // showType = line
                // if (factory.children.length === 0) {
                //   factory.disabled = false;
                // } else {
                for (const line of factory.children) {
                  if (line.children.length !== 0) {
                    line.children = [];
                  } else {
                    line.disabled = false; // 選擇產線時，將原先產線下無設備的 disabled 設為 false
                  }
                }
                // }
              }
            } else {
              factory.disabled = false; // 選擇工廠時，將原先工廠下無產線的 disabled 設為 false
            }
          }
        }
      }
    }

    return (
      <>
        <div
          className="add-device-left"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Tag.CheckableTag
            style={{
              margin: "0px",
              cursor: "pointer",
              lineHeight: "24px",
              height: "24px",
            }}
            checked={showType === "factory"}
            onChange={(checked) => {
              if (showType !== "factory") {
                setShowType("factory");
                setSelectedFactoryOrLine(null);
                setCurrentSelectFactoryOrLine([]);
              }
            }}
          >
            <FormattedMessage id="setting.factory" />
          </Tag.CheckableTag>

          <div style={{ margin: "0px 4px" }}> / </div>
          <Tag.CheckableTag
            style={{
              margin: "0px",
              cursor: "pointer",
              lineHeight: "24px",
              height: "24px",
            }}
            checked={showType === "line"}
            onChange={(checked) => {
              if (showType !== "line") {
                setShowType("line");
                setSelectedFactoryOrLine(null);
                setCurrentSelectFactoryOrLine([]);
              }
            }}
          >
            <FormattedMessage id="setting.line" />
          </Tag.CheckableTag>
        </div>
        <div
          className="add-device-right"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Cascader
            placeholder={formatMessage({ id: `setting.${showType}` })}
            options={newData}
            fieldNames={{ label: "name", value: "uuid", children: "children" }}
            allowClear={true}
            onChange={getSelectFactoryAndLine}
            style={{ width: "100%" }}
            value={currentSelectFactoryOrLine}
          />
        </div>
      </>
    );
  };

  const renderComponentSetting = () => {
    switch (selectedType) {
      case "device":
        return addDevice();
      case "word":
        return addWord();
      case "deviceStatus":
        return addDeviceStatus();
      default:
        return null;
    }
  };

  return props.deviceData !== null ? (
    <Modal
      bodyStyle={{ minHeight: "520px" }}
      centered
      title={
        props.getEditItem !== null ? (
          <FormattedMessage id="setting.editSymbol" />
        ) : (
          <FormattedMessage id="setting.addSymbol" />
        )
      }
      visible={props.addAndEditModalVisible}
      onOk={() => {
        if (props.getEditItem === null) {
          // add
          if (addAndEditCanvasRef.current.addItem() !== null) {
            props.addHandleOk(selectedValue.current);
            props.canvasRef.canvas.add(addAndEditCanvasRef.current.addItem());
          } else {
            props.setAddAndEditModalVisible(false);
          }
        } else {
          // edit
          let saveEditDevice =
            addAndEditCanvasRef.current.saveEditDeviceCanvas();
          if (saveEditDevice !== null) {
            saveEditDevice.set({
              angle: originalAngleRef.current,
              originX: originalOriginXRef.current,
              originY: originalOriginYRef.current,
            });
            props.canvasRef.canvas.add(saveEditDevice);
            props.canvasRef.canvas.remove(props.getEditItem); // 把大畫布原本的物件刪除
            props.setGetEditItem(null);
            props.addHandleOk(selectedValue.current);
          } else {
            props.setAddAndEditModalVisible(false);
          }
        }
        reset();
        setSelectedType("device");
      }}
      onCancel={() => {
        if (props.getEditItem !== null) {
          // edit
          props.setNewDeviceOption(originEditDeviceRef.current, true); // deviceUUID, disabled  // edit進來時把 device disabled 設成 false, 關掉不設定的話 disabled 要改回true
        }
        reset();
        setSelectedType("device");
        props.setAddAndEditModalVisible(false);
        props.setGetEditItem(null);
      }}
      okText={
        props.getEditItem !== null ? (
          <FormattedMessage id="setting.send" />
        ) : (
          <FormattedMessage id="setting.add" />
        )
      }
      cancelText={<FormattedMessage id="setting.cancel" />}
      destroyOnClose={true}
    >
      <div>
        <div className="add-device-modal">
          <div className="add-device-left">
            <FormattedMessage id="setting.type" />
          </div>
          <div className="add-device-right">
            <Select
              value={selectedType}
              onChange={handleChangeSelectedType}
              style={{ width: "100%", marginRight: "5px" }}
              disabled={props.getEditItem !== null ? true : false}
            >
              <Option value="device">
                <FormattedMessage id="setting.device" />
              </Option>
              <Option value="word">
                <FormattedMessage id="setting.word" />
              </Option>
              <Option value="deviceStatus">
                <FormattedMessage id="setting.deviceStatus" />
              </Option>
            </Select>
          </div>

          {renderComponentSetting()}

          <div className="add-device-left" />
          <div
            className="add-device-right"
            style={{
              height: "332px",
              width: "100%",
              justifyContent: "center", // 水平置中
              alignItems: "center", // 垂直置中
            }}
          >
            {selectedType === "device" ? (
              <div style={{ marginBottom: "5px" }}>
                <Button
                  style={{ marginRight: "2px" }}
                  onClick={() => {
                    addAndEditCanvasRef.current.bringForward();
                  }}
                >
                  <FormattedMessage id="setting.bringForward" />
                </Button>

                <Button
                  style={{ marginRight: "2px" }}
                  onClick={() => {
                    addAndEditCanvasRef.current.sendBackwards();
                  }}
                >
                  <FormattedMessage id="setting.sendBackwards" />
                </Button>
              </div>
            ) : null}
            <AddAndEditCanvas
              selectedSymbol={selectedSymbol}
              selectedSymbolPos={selectedSymbolPos}
              selectedDevice={selectedDevice}
              selectedDevicePos={selectedDevicePos}
              ref={addAndEditCanvasRef}
              getEditItem={props.getEditItem}
              selectedValue={selectedValue.current}
              setAddAndEditModalVisible={props.setAddAndEditModalVisible}
              objectOrder={objectOrder}
              scaleFactorRef={scaleFactorRef.current}
              selectedType={selectedType}
              inputValue={inputValue}
              wordExtraProperties={wordExtraProperties}
              selectedFactoryOrLine={selectedFactoryOrLine}
              selectedFactoryOrLineStatus={selectedFactoryOrLineStatus}
            />
          </div>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default AddAndEditModal;
