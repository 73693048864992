export const statusColors = {
  0: "rgb( 191, 189, 194)", //'gray'
  1: "rgb( 83, 170, 45)", //'green'
  2: "rgb( 249, 153, 30)", // 'orange'
  3: "rgb( 255, 7, 70)", // 'red'
};

export const workOrderInfoMap = [
  {
    key: "work_order_id",
  },
  {
    key: "product_number",
  },
  {
    key: "start_time",
  },
  {
    key: "worker_name",
  },
  {
    key: "workOrderProgress",
  },
  {
    key: "qty",
  },
  {
    key: "abnormal_status",
  },
];

export const canvasScale = [
  {
    type: "fullScreen",
    scale: 1,
  },
  {
    type: "wideScreen",
    scale: 2.794,
  },
  {
    type: "laptop",
    scale: 2.74,
  },
];
