import React from "react";
import { Button } from "antd";

const ToolbarButton = (props) => {
  return (
    <>
      <Button
        type="primary"
        ghost
        style={{ marginRight: "2px", marginBottom: "2px" }}
        onClick={() => {
          props.canvasRef.editItem(); // 編輯
        }}
      >
        {props.formatMessage({ id: "setting.edit" })}
      </Button>

      <Button
        type="primary"
        style={{ marginRight: "2px", marginBottom: "2px" }}
        onClick={() => {
          props.canvasRef.copyItem(); // 複製
        }}
      >
        {props.formatMessage({ id: "setting.copy" })}
      </Button>

      <Button
        danger
        style={{ marginRight: "2px", marginBottom: "2px" }}
        onClick={() => {
          props.canvasRef.deleteItem(); // 刪除
        }}
      >
        {props.formatMessage({ id: "setting.delete" })}
      </Button>

      <Button
        style={{ marginRight: "2px", marginBottom: "2px" }}
        onClick={() => {
          props.canvasRef.bringToFront(); // 至最上層
        }}
      >
        {props.formatMessage({ id: "setting.bringToFront" })}
      </Button>

      <Button
        style={{ marginRight: "2px", marginBottom: "2px" }}
        onClick={() => {
          props.canvasRef.bringForward(); // 往上一層
        }}
      >
        {props.formatMessage({ id: "setting.bringForward" })}
      </Button>

      <Button
        style={{ marginRight: "2px", marginBottom: "2px" }}
        onClick={() => {
          props.canvasRef.sendBackwards(); // 往下一層
        }}
      >
        {props.formatMessage({ id: "setting.sendBackwards" })}
      </Button>

      <Button
        style={{ marginRight: "2px", marginBottom: "2px" }}
        onClick={() => {
          props.canvasRef.sendToBack(); // 至最下層
        }}
      >
        {props.formatMessage({ id: "setting.sendToBack" })}
      </Button>
    </>
  );
};

export default ToolbarButton;
