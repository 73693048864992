import axios from 'axios';
import Cookies from 'js-cookie';

let request;

export const initializeFilestoreApi = () => {
  request = axios.create({
    baseURL: process.env.REACT_APP_FILESTORE_URL,
  });

  request.interceptors.request.use( (config) => {
    config.headers["Authorization"] = `Bearer ${Cookies.get("token")}`;
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
};

// --------------------------------------------------------------
// filestore api
export const apiNewFile = (data) => {
  return request.put('files', data).then( (res) => {
    return res.data;
  });
};

export const apiDeleteFile = (path) => {
  return request.delete('files', {
    data: { path: path }
  }).then( (res) => {
    return res;
  });
};

export const apiGetFileTempLink = (filePath) => {
  return request.post('tempLinks', {
    path: filePath,
    expiry: 60  // set as 60s
  }).then( (res) => {
    return res.data;
  });
};

