import React, { Component } from 'react'
import { fabric } from 'fabric';
import { statusColors } from '../KeyMap';
import initAligningGuidelines from './AligningGuidelines';

class AddAndEditCanvas extends Component {
  constructor(props) {
    super(props);
    this.device = '';
    this.deviceUUID= '';
    this.symbolLoad = false;
    this.selectedType = '';
    this.selectedProperty = {};
  }

  componentDidMount() {
    // Make a New Canvas
    this.canvas = new fabric.Canvas('addAndEditCanvas', {
      preserveObjectStacking: true,  //選中的物件不會置頂
      height: 300,
      width: 300
    });

    // 設定邊界
    this.canvas.on('object:moving', e => {
      const obj = e.target
      obj.setCoords()
      const { top, left, width, height } = obj.getBoundingRect()
      if (top < 0) {
        obj.top = 0
      }
      if (top + height > this.canvas.getHeight()) {
        obj.top = this.canvas.getHeight() - height
      }
      if (left < 0) {
        obj.left = 0
      }
      if (left + width > this.canvas.getWidth()) {
        obj.left = this.canvas.getWidth() - width
      }
      this.canvas.renderAll();
    });

    const objectSetting = {
      lockScalingFlip: true, // 不能把物件拉成左右相反
      _controlsVisibility: { // 物件只能等比例拖拉放大縮小
        "bl": true,
        "br": true,
        "mb": false,
        "ml": false,
        "mr": false,
        "mt": false,
        "tl": true,
        "tr": true,
        "mtr": false,
      }
    };
    fabric.Text.prototype.set(objectSetting);
    fabric.Group.prototype.set(objectSetting);
    fabric.Image.prototype.set(objectSetting);

    if (this.props.selectedType === 'device') { //device
      // console.log('11111-device')
      if (this.props.objectOrder.length > 0) {
        if (this.props.objectOrder[0] === 'text') {
          this.addText(this.props.selectedDevice, this.props.selectedDevicePos, this.props.selectedType).then(() => {
            this.addSymbol(this.props.selectedSymbol, this.props.selectedSymbolPos);
          });
        } else {
          this.addSymbol(this.props.selectedSymbol, this.props.selectedSymbolPos).then(() => {
            this.addText(this.props.selectedDevice, this.props.selectedDevicePos, this.props.selectedType);
          });
        }
      }
    } else if (this.props.selectedType === 'word') { // word
      // console.log('2222-word', this.props)
      const text = new fabric.Text(this.props.inputValue, {
        fontFamily: 'Arial',
        fill: this.props.wordExtraProperties.color,
        backgroundColor: this.props.wordExtraProperties.backgroundColor
      });
      this.canvas.add(text);
      this.selectedType = this.props.selectedType;
    } else if (this.props.selectedType === 'deviceStatus') { // deviceStatus
      // console.log('3333-deviceStatus', this.props)
      for (const item of this.props.selectedFactoryOrLineStatus) {
        this.canvas.add(item);
      }
      this.selectedType = this.props.selectedType;
      this.selectedProperty = this.props.getEditItem.selectedProperty;
    }
    initAligningGuidelines(this.canvas);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selectedType !== undefined && nextProps.selectedType !== this.props.selectedType) {
      this.canvas.clear();
    } else {
      if (nextProps.selectedType === 'device') { // device
        // console.log('[device]')
        // this.canvas.clear();
        if (nextProps.objectOrder.length > 0) {
          if (nextProps.objectOrder[0] === 'text') {
            this.addText(nextProps.selectedDevice, nextProps.selectedDevicePos, nextProps.selectedType).then(() => {
              this.addSymbol(nextProps.selectedSymbol, nextProps.selectedSymbolPos);
            });
          } else {
            this.addSymbol(nextProps.selectedSymbol, nextProps.selectedSymbolPos).then(() => {
              this.addText(nextProps.selectedDevice, nextProps.selectedDevicePos, nextProps.selectedType);
            });
          }
        }
      } else if (nextProps.selectedType === 'word') { // word
        // console.log('[word]')
        // this.canvas.clear();
        if ( nextProps.inputValue !== this.props.inputValue) {
          if( nextProps.inputValue !== '' ) {
            if( this.canvas.getObjects().length > 0 ) {
              this.canvas.getObjects()[0].clone( (text) => {
                text.selectedType = nextProps.selectedType;
                text.set({
                  fill: nextProps.wordExtraProperties.color,
                  backgroundColor: nextProps.wordExtraProperties.backgroundColor,
                  text: nextProps.inputValue,
                });
                this.canvas.clear();
                this.canvas.add(text);
              });
            } else {
              const text = new fabric.Text(nextProps.inputValue, {
                fontFamily: 'Arial',
                fill: nextProps.wordExtraProperties.color,
                backgroundColor: nextProps.wordExtraProperties.backgroundColor,
                left: 0,
                top: 0
              });
              text.selectedType = nextProps.selectedType;
              this.canvas.add(text);
            }
          } else {
            this.canvas.clear();
          }
        }

        if( this.canvas.getObjects().length > 0 ) {
          if (nextProps.wordExtraProperties.color !== this.props.wordExtraProperties.color) {
            const text = this.canvas.getObjects()[0];
            text.set({
              fill: nextProps.wordExtraProperties.color
            });
          }

          if (nextProps.wordExtraProperties.backgroundColor !== this.props.wordExtraProperties.backgroundColor) {
            const text = this.canvas.getObjects()[0];
            text.set({
              backgroundColor: nextProps.wordExtraProperties.backgroundColor
            });
          }
        }
        this.canvas.renderAll();
        this.selectedType = nextProps.selectedType;
      } else if (nextProps.selectedType === 'deviceStatus') {
        this.canvas.clear();
        // console.log('[deviceStatus]')
        let left = 0;
        if ( nextProps.selectedFactoryOrLine !== null ) {
          for (let key = 1; key <= Object.entries(statusColors).length; key ++) {
            const value = key === Object.entries(statusColors).length ? statusColors[0] : statusColors[key];
            const circle = new fabric.Circle({
              radius: 30,
              fill: value,
              originX: 'center',
              originY: 'center'
            });

            const text = new fabric.Text('999', {
              fontSize: 30,
              originX: 'center',
              originY: 'center',
              fontFamily: 'Arial'
            });

            const group = new fabric.Group([circle, text], {
              left: left,
              top: 60
            });
            group.status = key === 4 ? 0 : key; // 灰色status 0 但位置在第4
            this.canvas.add(group);
            left += 63;
          }
          const title = new fabric.Text(nextProps.selectedFactoryOrLine.factoryOrLine.name, {
            fontFamily: 'Arial',
            fontSize: 30,
            originX: 'center',
            left: 120,
            top: 15
          });
          this.selectedType = nextProps.selectedType;
          this.selectedProperty = { type: nextProps.selectedFactoryOrLine.type, uuid: nextProps.selectedFactoryOrLine.factoryOrLineUUID };
          this.canvas.add(title);
        }
      }
    }
  }

  // symbol
  addSymbol = (selectedSymbol, selectedSymbolPos) => {
    return new Promise( (resolve, reject) => {
      try {
        if (selectedSymbol.uri !== null ) { // add - 有選擇圖標 / edit - 編輯的物件有圖片
          if (this.symbolLoad === false) {
            this.symbolLoad = true;
            const symbolURL = selectedSymbol.uri;
            let findImage = this.canvas._objects.find((item) => item.type === 'image');
            if (findImage === undefined) { // 原小畫布上沒有圖片 => 新增圖片
              new fabric.Image.fromURL(symbolURL, (img) => {
                let scaleFactor = this.scaleSymbol(img);
                img.set({
                  left: selectedSymbolPos.left,
                  top: selectedSymbolPos.top,
                  originX: 'left',
                  originY: 'top',
                  scaleX: selectedSymbol.scaleX === undefined ? scaleFactor : selectedSymbol.scaleX,
                  scaleY: selectedSymbol.scaleY === undefined ? scaleFactor : selectedSymbol.scaleY
                });
                img.name = selectedSymbol.name;
                this.canvas.add(img);
                this.symbolLoad = false;
                resolve();
              });
            } else { // 原小畫布上有圖片 => 替換圖片
              findImage.setSrc(symbolURL, (img) => {
                if (findImage.left + img.width * findImage.scaleX > 300) {
                  img.set({
                    left: findImage.left - (findImage.left + img.width * findImage.scaleX - 300)
                  });
                }

                if (findImage.top + img.height * findImage.scaleY > 300) {
                  img.set({
                    top: findImage.top - (findImage.top + img.height * findImage.scaleY - 300)
                  });
                }
                findImage.name = selectedSymbol.name;
                this.canvas.renderAll();
                this.symbolLoad = false;
                resolve();
              });
            }
          }
        } else { // 把原畫布上的圖標刪除
          let findImage = this.canvas._objects.find((item) => item.type === 'image');
          if (findImage !== undefined) {
            this.canvas.remove(findImage);
            this.canvas.renderAll();
            resolve();
          }
        }
      } catch(err) {
        console.log('AddAndEditCanvas - addSymbol', err);
        reject('Error');
      }
    });
  }

  // text
  addText = (selectedDevice, selectedDevicePos, selectedType) => {
    return new Promise( (resolve, reject) => {
      try {
        if (selectedDevice !== null ) { // add - 有選擇設備 / edit - 編輯的物件有設備
          let findText = this.canvas._objects.find((item) => item.type === 'text');
          if (findText === undefined) { // 原小畫布上沒有設備 => 新增設備
            const newText = new fabric.Text(selectedDevice.device.name, {
              left: selectedDevicePos.left,
              top: selectedDevicePos.top,
              fill: 'black',  // 字體顏色
              backgroundColor: 'gray', // 底色
              fontFamily: 'Arial'
            });
            newText.scaleX = selectedDevice.scaleX === undefined ? 1 : selectedDevice.scaleX;
            newText.scaleY = selectedDevice.scaleY === undefined ? 1 : selectedDevice.scaleY;
            newText.device = selectedDevice.device;
            newText.deviceUUID = selectedDevice.deviceUUID;
            this.device = selectedDevice.device;
            this.deviceUUID= selectedDevice.deviceUUID;
            this.selectedType = selectedType;
            this.canvas.add(newText);
            resolve();
          } else { // 原小畫布上有設備 => 替換設備 (目前有clear 所以不會進這)
            findText.set({
              text: selectedDevice.device.name
            });
            findText.device = selectedDevice.device;
            findText.deviceUUID = selectedDevice.deviceUUID;
            this.device = selectedDevice.device;
            this.deviceUUID= selectedDevice.deviceUUID;
            this.canvas.renderAll();
            if (findText.left + findText.width > 300) {
              findText.set({
                left: findText.left - (findText.left + findText.width - 300)
              });
              this.canvas.renderAll();
            }
            resolve();
          }
        } else { // 把原畫布上的設備刪除
          let findText = this.canvas._objects.find((item) => item.type === 'text');
          if (findText !== undefined) {
            this.canvas.remove(findText);
            this.canvas.renderAll();
            resolve();
          }
        }
      } catch(err) {
        console.log('AddAndEditCanvas - addSymbol', err);
        reject('Error');
      }
    });
  }

  // 調整上傳圖標的大小
  scaleSymbol = (img) => {
    let imgWidth = img.width;
    let imgHeight = img.height;
    let imgAspect = imgWidth / imgHeight;
    let max = 200; // 最大寬高
    let scaleFactor;

    if (imgAspect >= 1) {
      // 橫向圖片
      scaleFactor = imgWidth > max ? max / imgWidth : 1;
    } else {
      // 直向圖片
      scaleFactor = imgHeight > max ? max / imgHeight : 1;
    }
    return scaleFactor;
  }

   // 往上一層
   bringForward = () => {
    this.canvas.bringForward(this.canvas.getActiveObject());
  }

  // 往下一層
  sendBackwards = () => {
    this.canvas.sendBackwards(this.canvas.getActiveObject());
  }

  // add - add
  addItem = () => {
    if (this.canvas.getObjects().length > 1) { // device + symbol
      let groupObj = new fabric.Group(this.canvas.getObjects(), {
        left: 0,
        top: 0
      });
      if (this.selectedType === 'device') {
        groupObj.device = this.device;
        groupObj.deviceUUID = this.deviceUUID;
      } else if (this.selectedType === 'deviceStatus') {
        groupObj.selectedProperty = this.selectedProperty;
      }
      groupObj.selectedType = this.selectedType;
      return groupObj;
    } else if (this.canvas.getObjects().length === 0) { // length === 0
      return null;
    } else { // device / symbol / word
      if (this.selectedType === 'word') {
        this.canvas.getObjects()[0].left = 0;
        this.canvas.getObjects()[0].top = 0;
      }
      return this.canvas.getObjects()[0];
    }
  }

  // edit - save
  saveEditDeviceCanvas = () => {
    if (this.canvas.getObjects().length > 1) { // device + symbol / deviceStatus
      let objectsToAddBack = [];
      for (const object of this.canvas.getObjects()) {
        object.set({
          left: object.left / this.props.scaleFactorRef,
          top: object.top / this.props.scaleFactorRef
        });
        if (object.type === 'image') { // image
          object.set({
            scaleX: object.scaleX / this.props.scaleFactorRef,
            scaleY: object.scaleY / this.props.scaleFactorRef
          });
        } else { // text
          object.scale(object.scaleX / this.props.scaleFactorRef);
        }
        objectsToAddBack.push(object);
      }
      this.canvas.renderAll();
      let group = new fabric.Group(objectsToAddBack, {
        left: this.props.getEditItem.left,
        top: this.props.getEditItem.top,
        scaleX: this.props.getEditItem.getObjectScaling().scaleX,
        scaleY: this.props.getEditItem.getObjectScaling().scaleY
      });
      if (this.selectedType === 'device') {
        group.device = this.device;
        group.deviceUUID = this.deviceUUID;
      } else if (this.selectedType === 'deviceStatus') {
        group.selectedProperty = this.selectedProperty;
      }
      group.selectedType = this.selectedType;
      // console.log('[[[[[group]]]]]', group)
      return group;
    } else if (this.canvas.getObjects().length === 1) { // device / symbol / word
      this.canvas.getObjects()[0].set({
        left: this.props.getEditItem.left,
        top: this.props.getEditItem.top,
        scaleX: this.props.getEditItem.scaleX,
        scaleY: this.props.getEditItem.scaleY,
        selectedType: this.selectedType
      })
      return this.canvas.getObjects()[0];
    } else { // length === 0
      return null;
    }
  }

  render() {
    return (
      <div className="edit-device-modal">
        <canvas id="addAndEditCanvas" ref="myFabric"/>
      </div>
    );
  }
}
export default AddAndEditCanvas;