const obj = {
  "view.backToSetting": "回設定頁面",
  "view.gotoSetting": "修改平面圖",
  "view.iframeURL": "嵌入網址",
  /* --- No Permission --- */
  "view.noReadWritePromissionTitle": "沒有讀取權限",
  "view.noReadWritePromissionSubTitle": "請檢查權限",
  "view.noMatchCanvasTitle": "您尚未設定廠區平面圖",
  "view.noMatchCanvasSubTitle": "請點選下方按鈕前往設定",
  "view.goToSetting": "設定廠區平面圖",
};

export default obj;
