import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Canvas from "../Canvas";
import { apiGetPlantFloor } from "../../api/topology";
import { SettingOutlined, LinkOutlined, SyncOutlined } from "@ant-design/icons";
import { Tooltip, Button, Modal, message } from "antd";
import NoPermission from "../NoPermission";
import { PermissionContext } from "../../App";
import * as copy from "copy-to-clipboard";
import { FormattedMessage, useIntl } from "react-intl";

const View = () => {
  const { formatMessage } = useIntl();

  const [viewFrom, setViewFrom] = useState("default");
  const [plantFloorCanvas, setPlantFloorCanvas] = useState(null);
  const [intervalTime, setIntervalTime] = useState(30);
  const [permission, setPermission] = useState("loading");
  const permissionContext = useContext(PermissionContext);
  const topologyType = useRef("");
  const topologyUuid = useRef("");

  useEffect(() => {
    if (permissionContext.read !== false) {
      // 有topology read權限
      const parameters = queryString.parse(window.location.search);
      if (parameters.viewFrom !== null && parameters.viewFrom !== undefined) {
        if (parameters.viewFrom === "portal") {
          setViewFrom("portal");
        }
      }

      const str = window.location.pathname;
      let path = str.split("/");
      if (path[1] === "view" && path[2] !== undefined && path[2] !== "") {
        // view/uuid
        apiGetPlantFloor(path[2])
          .then((res) => {
            setPlantFloorCanvas(res.canvas);
            if (res.intervalTime !== 0) {
              setIntervalTime(res.intervalTime);
            }

            topologyType.current = res.topologyType;
            topologyUuid.current = res.topologyUuid;
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status.toString() === "401") {
                // 無topology read權限
                setPermission("noReadWritePromission");
              } else if (err.response.status.toString() === "500") {
                // 無瀏覽此tenant平面圖的權限
                setPermission("noMatchCanvasUuid");
              }
            }
          });
      }
    } else {
      // 無topology read權限
      setPermission("noReadWritePromission");
    }
  }, [permissionContext]);

  const getSettingLink = () => {
    const str = window.location.pathname;
    let path = str.split("/");

    return window.location.origin + "/setting/" + path[2];
  };

  const getEmbeddedLinkModal = () => {
    Modal.success({
      title: formatMessage({ id: "view.iframeURL" }),
      content: (
        <div
          style={{ marginTop: "24px" }}
          className="copyCanvasUrl"
          onClick={function () {
            let result = copy(
              window.location.origin + window.location.pathname,
              { format: "text/plain" }
            );
            if (result === true) {
              message.success(
                formatMessage({ id: "setting.canvas.copySuccess" })
              );
            }
          }}
        >
          {window.location.origin + window.location.pathname}
        </div>
      ),
      closable: true,
      maskClosable: true,
      okText: formatMessage({ id: "setting.copy" }),
      okButtonProps: {
        onClick: () => {
          let result = copy(window.location.origin + window.location.pathname, {
            format: "text/plain",
          });
          if (result === true) {
            message.success(
              formatMessage({ id: "setting.canvas.copySuccess" })
            );
          }
          return true;
        },
      },
    });
  };

  return plantFloorCanvas !== null ? (
    <div>
      {viewFrom === "default" ? (
        <Tooltip
          placement="left"
          title={<FormattedMessage id="view.backToSetting" />}
        >
          <SettingOutlined
            className="viewSettingIcon"
            onClick={() => {
              window.open(getSettingLink());
            }}
          />
        </Tooltip>
      ) : viewFrom === "portal" ? (
        <div style={{ height: "34px" }}>
          <Link
            to={{ pathname: getSettingLink() }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              size="small"
              icon={<SettingOutlined />}
              style={{ margin: "5px 10px" }}
            >
              <FormattedMessage id="view.gotoSetting" />
            </Button>
          </Link>
          <Button
            size="small"
            icon={<LinkOutlined />}
            style={{ margin: "5px 10px 5px 0px" }}
            onClick={() => getEmbeddedLinkModal()}
          >
            {" "}
            <FormattedMessage id="view.iframeURL" />
          </Button>
          <Button
            size="small"
            icon={<SyncOutlined />}
            style={{ margin: "5px 10px 5px 0px" }}
            onClick={() => window.location.reload()}
          />
        </div>
      ) : null}
      <Canvas
        topologyType={topologyType.current}
        topologyUuid={topologyUuid.current}
        intervalTime={intervalTime}
        plantFloorCanvas={plantFloorCanvas}
      />
    </div>
  ) : (
    <NoPermission permission={permission} />
  );
};

export default View;
